<template>
  <div class="relative">
    <div
      v-if="heroVideo"
      class="hero-media"
      :class="{
        'has-title': hasTitle,
      }"
    >
      <MediaVideo
        v-bind="heroVideo"
        ref="mediaVideo"
        autoplay
        loop
        :controls="false"
        video-aria-hidden
        muted
        :image-style="imageStyles"
        poster=""
      />
    </div>
    <MediaImage
      v-else-if="hero"
      v-blokkli-droppable:field_hero_image
      hide-caption
      v-bind="entity?.hero || hero"
      :image-style="imageStyles"
      preload
      loading="eager"
      class="hero-media relative"
      :class="{
        'has-title': hasTitle,
      }"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  MediaVideoFragment,
} from '#graphql-operations'

const { breakpoints } = useTailwind()

defineProps<{
  hero?: MediaImageFragment
  heroVideo?: MediaVideoFragment
  hasTitle?: boolean
  entity?: { hero?: MediaImageFragment }
}>()

const imageStyles = defineImageStyle({
  type: 'pictures',
  pictures: {
    default: {
      width: breakpoints.xs,
      aspectRatio: 9 / 16,
    },
    xs: {
      width: breakpoints.sm,
      aspectRatio: 4 / 3,
    },
    sm: {
      width: breakpoints.md,
      aspectRatio: 16 / 9,
    },
    md: {
      width: breakpoints.lg,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: breakpoints.xl,
      aspectRatio: 16 / 9,
    },
    xl: {
      width: 2561,
      aspectRatio: 16 / 9,
    },
  },
})

defineOptions({
  name: 'PageHero',
})
</script>

<style lang="postcss">
.hero-media {
  @apply mx-auto;

  /* When the page has a page title, we also need to remove this amount from the */
  /* available height of the hero. */
  &.has-title {
    /* We assume the title only has a single line of text in most cases. */
    --hero-title-height: calc(
      var(--spacing-page-title) * 2 + var(--typo-h1-size)
    );
  }

  @supports (height: 100svh) {
    /* Use svh unit. 100svh is the smallest possible viewport height. Basically */
    /* the height of the viewport minus any elements that may appear or disappear */
    /* during scrolling (address bar, tab/nav bars, etc.) */
    --small-viewport-height: 100svh;
    /* Since we can use the svh unit, we don't need the fixed browser navbar size anymore. */
    --hero-height-browser-navbar: 0px;
  }

  /* Assume that browsers from this viewport on that don't support svh anyway */
  /* don't display any navbars that overlap 100vh. */
  @screen md {
    --hero-height-browser-navbar: 0px;
  }

  /* The ideal height for the hero so that: */
  /* - it fills the screen top to bottom of the viewport */
  /* - makes sure the anchor nav is always visible */
  --hero-height: calc(
    var(--small-viewport-height, 100vh) - var(--header-height) -
      var(--hero-height-browser-navbar, 80px) - var(--hero-title-height, 0px)
  );

  height: var(--hero-height);
  max-height: 177vw;
  min-height: 500px;
  @screen xs {
    max-height: 80vw;
  }
  @media (max-aspect-ratio: 16/10), (min-width: 2520px) {
    @apply max-w-screen-xxl;
  }
  video {
    width: 100%;
    max-width: none;
  }

  picture {
    @apply absolute top-0 left-0 size-full;

    img {
      @apply relative size-full object-cover;
    }
  }

  /* hide all calutter */
  .vjs-controls-disabled .vjs-control-bar,
  .vjs-loading-spinner,
  .vjs-hidden {
    display: none !important;
  }

  .video-js.vjs-fluid {
    height: 0;
    width: 100%;
  }
  .video-js .vjs-tech {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
