<template>
  <div
    class="size-aware-item"
    :class="['size-aware-item--' + size, 'size-aware-item--' + align]"
  >
    <slot :image-style="imageStyle" />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size: 'xl' | 'large' | 'medium' | 'small' | 'xs'
    align?: 'left' | 'right'
  }>(),
  {
    align: 'left',
  },
)

const { maxWidthForCols, breakpoints, rokkaMax } = useTailwind()

const xs = defineImageStyle({
  type: 'sizes',
  sizes: {
    default: maxWidthForCols('default', 4),
    xs: maxWidthForCols('xs', 4),
    sm: maxWidthForCols('sm', 4),
    md: maxWidthForCols('md', 2),
    lg: maxWidthForCols('lg', 2),
  },
})

const small = defineImageStyle({
  type: 'sizes',
  sizes: {
    default: maxWidthForCols('default', 4),
    xs: maxWidthForCols('xs', 4),
    sm: maxWidthForCols('sm', 4),
    md: maxWidthForCols('md', 4),
    lg: maxWidthForCols('lg', 6),
  },
})

const medium = defineImageStyle({
  type: 'sizes',
  sizes: {
    default: maxWidthForCols('default', 5),
    xs: maxWidthForCols('xs', 5),
    sm: maxWidthForCols('sm', 5),
    md: maxWidthForCols('md', 5),
    lg: maxWidthForCols('lg', 8),
  },
})

const large = defineImageStyle({
  type: 'sizes',
  sizes: {
    default: maxWidthForCols('default', 6),
    xs: maxWidthForCols('xs', 6),
    sm: maxWidthForCols('sm', 6),
    md: maxWidthForCols('md', 6),
    lg: maxWidthForCols('lg', 12),
  },
})

const xl = defineImageStyle({
  type: 'sizes',
  sizes: {
    xs: breakpoints.sm,
    sm: breakpoints.md,
    md: breakpoints.lg,
    lg: breakpoints.xl,
    xl: breakpoints.xxl,
    xxl: rokkaMax,
  },
})

const imageStyle = computed(() => {
  switch (props.size) {
    case 'xs':
      return xs
    case 'small':
      return small
    case 'medium':
      return medium
    case 'large':
      return large
    default:
      return xl
  }
})
</script>

<style lang="postcss">
.size-aware-item {
  --item-span: 12;
  --item-start: 1;
  --item-end: calc(var(--item-start) + var(--item-span));
  --item-column-start: auto;

  grid-column-start: var(--item-start);
  grid-column-end: var(--item-end);

  &.size-aware-item--span-only {
    grid-column-start: span var(--item-span);
    grid-column-end: auto;
  }

  &--right {
    --item-end: calc(var(--columns) + 1);
    --item-start: calc(var(--columns) - var(--item-span));
  }

  &--xs {
    --item-span: 4;
    @screen md {
      --item-span: 2;
    }
  }

  &--small {
    --item-span: 6;
  }

  &--medium {
    --item-span: 9;
    @screen md {
      --item-span: 8;
    }
  }
}
</style>
