export default function () {
  const ssrDataLayer = useState<string[][]>('ssrDataLayer', () => [])

  function dataLayerPush(data: string[]) {
    if (import.meta.server) {
      ssrDataLayer.value.push(data)
    } else {
      try {
        if (!window._paq) {
          window._paq = []
        }
        window._paq.push(data)
      } catch {
        // Noop.
      }
    }
  }

  function trackSwitchPage(url: string, title: string) {
    dataLayerPush(['setCustomUrl', url])
    dataLayerPush(['setDocumentTitle', title])
    dataLayerPush(['trackPageView'])
  }

  return {
    dataLayerPush,
    trackSwitchPage,
  }
}

declare global {
  interface Window {
    _paq: string[][]
  }
}
