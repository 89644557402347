<template>
  <nav :aria-label="$texts('accessibility.meta_navigation', 'Metanavigation')">
    <ul>
      <li
        v-for="(link, index) in links"
        :key="`global_${index}`"
        class="first:pt-0 pt-2.5"
      >
        <DrupalLink
          :to="link.link?.url?.path"
          class="text-base link-underline"
          active-class="active"
        >
          {{ link.link.label }}
        </DrupalLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const initData: Ref<InitData> = await useInitData()

const links = computed(() => {
  return initData.value.footerMetaMenuLinks
})
</script>
