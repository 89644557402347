<template>
  <div v-if="isMenuOpen" class="h-header" />

  <header
    ref="headerEl"
    class="z-[100]"
    :class="isMenuOpen ? 'fixed left-0 right-0' : 'sticky'"
    :style="{
      top: isMenuOpen ? offsetY + 'px' : '0px',
    }"
  >
    <div class="bg-white h-header">
      <Container class="flex justify-between items-center h-full">
        <div class="mr-auto">
          <LogoMuseumHome class="text-black hover:text-stone-500" />
        </div>
        <div class="flex relative z-[999999]">
          <PageMenuLanguageSwitch
            :is-high-contrast="isMenuOpen"
            class="pr-2"
            :class="{
              'hidden md:block': !isMenuOpen,
            }"
          />

          <PageMenuOpenSwitch
            :model-value="isMenuOpen"
            @update:model-value="isMenuOpen ? onMenuClose() : onMenuOpen()"
          />
        </div>
      </Container>
    </div>

    <Transition name="menu">
      <div
        v-show="isMenuOpen"
        ref="menuEl"
        class="fixed left-0 z-40 bg-black inset-y-0 w-screen overflow-auto overscroll-contain"
        :style="style"
        @click.capture="onClickInsideMenu"
      >
        <div class="sticky top-0 bg-black z-40 h-header">
          <Container class="flex justify-between items-center h-full">
            <div>
              <LogoMuseumHome class="text-white hover:text-stone-700" />
            </div>
          </Container>
        </div>
        <div class="menu-inner will-change-transform">
          <PageMenuGroup :title="$texts('header.menu', 'Menü')">
            <MainMenu />
          </PageMenuGroup>

          <PageMenuGroup :title="$texts('header.target_group', 'Angebote für')">
            <TargetGroupMenu />
          </PageMenuGroup>

          <PageMenuGroup
            :title="$texts('header.locations', 'Standorte & Öffnungszeiten')"
            title-tag="h1"
          >
            <LocationsMenu />
          </PageMenuGroup>
        </div>
      </div>
    </Transition>
  </header>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

const openedOnce = ref(false)
const isMenuOpen = ref(false)
const scrollBarGap = ref(0)
const offsetY = ref(0)
const menuEl = ref<HTMLDivElement | null>(null)
const headerEl = ref<HTMLElement | null>(null)

const style = computed(() => {
  if (!openedOnce.value) {
    return
  }

  return {
    top: offsetY.value + 'px',
  }
})

function onMenuOpen() {
  if (!menuEl.value || !headerEl.value || isMenuOpen.value) {
    return
  }

  scrollBarGap.value =
    window.innerWidth - document.documentElement.getBoundingClientRect().width

  openedOnce.value = true

  // Update the y offset. This is needed because when logged in, the header
  // element is not always positioned fully at the top of the screen.
  // By measuring its position on the y axis (relativ to the viewport), we
  // can set the height of the menu overlay so that it occupies the exact space
  // available.
  const rect = headerEl.value.getBoundingClientRect()
  offsetY.value = Math.ceil(rect.y)

  // This triggers the open transition.
  isMenuOpen.value = true

  // Must be wrapped in nextTick because the element is still hidden at this
  // point. After the next tick, the transition has started and the element
  // is not display: none anymore.
  nextTick(() => {
    if (!menuEl.value) {
      return
    }

    // Reset the scroll position when opening the menu.
    menuEl.value.scrollTop = 0
  })
}

function onMenuClose() {
  if (!isMenuOpen.value) {
    return
  }
  isMenuOpen.value = false
}

function onClickInsideMenu(e: MouseEvent) {
  if (!(e.target instanceof HTMLElement || e.target instanceof SVGElement)) {
    return
  }

  const link = e.target.closest('a')
  if (!link) {
    return
  }

  // A link was clicked. Close the menu.
  onMenuClose()
}

// Close the menu when pressing "Escape".
onKeyStroke(['Escape'], onMenuClose)

// Close the menu when the route changes (e.g. after clicking a menu item).
onRouteChange(onMenuClose)
</script>

<style lang="postcss">
.menu-enter-active,
.menu-leave-active {
  @apply transition-all duration-[600ms] ease-swing;
  /* transition-duration: 20s; */
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }

  .menu-inner {
    transition: inherit;
  }
}

.menu-enter-from,
.menu-leave-to {
  clip-path: inset(0% 0px 100% 0px);

  .menu-inner {
    @apply opacity-20 -translate-y-12;
  }
}

.menu-leave-from,
.menu-enter-to {
  clip-path: inset(0px 0px 0px 0px);
}
</style>
