export enum DomainType {
  MUSEUM,
  PAVILLON,
}

type UseDomain = {
  domain: DomainType
  isPavillon: boolean
}

export function useDomain(): UseDomain {
  const url = useRequestURL()

  const domain = url.hostname.includes('corbusier')
    ? DomainType.PAVILLON
    : DomainType.MUSEUM

  const isPavillon = domain === DomainType.PAVILLON

  return { domain, isPavillon }
}
