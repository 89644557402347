<template>
  <div v-if="messages.length" class="fixed left-0 bottom-0 z-[9999999] w-full">
    <div
      v-for="(message, i) in messages"
      :key="i"
      class="flex items-center justify-between rounded p-6"
      :class="classes[message.type]"
      @click="removeMessage(i)"
    >
      <div v-html="message.message" />
      <SpriteSymbol name="close" class="size-6" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DrupalMessageType } from '~/composables/useDrupalMessages'

const { messages, removeMessage } = useDrupalMessages()

const classes: Record<DrupalMessageType, string> = {
  error: 'bg-red text-white',
  warning: 'bg-stone-200 text-black',
  status: 'bg-stone-200 text-black',
}
</script>
