<template>
  <div v-if="isEditing && parentType === 'teaser_list'" class="w-32">
    <MediaImage v-bind="media" hide-caption />
  </div>
  <div v-else :class="{ 'col-span-4 mb-4': isEditing }">
    <TeaserExhibition
      v-if="node && node.__typename === 'NodeExhibition'"
      v-bind="node"
      :title="title"
      :to="url"
      :teaser-text="text"
      :link-text="linkText"
    />
    <TeaserDefault
      v-else
      :to="url"
      :title="title"
      :text="text"
      :media="media"
      :link-text="linkText"
      :node="node"
      :is-editing="isEditing"
    />
  </div>
</template>

<script lang="ts" setup>
/**
 * This paragraph has a link field that allowed both internal and external links.
 * In case of external links, the title, text and media fields are required. For
 * internal links, they are optional.
 *
 * The title, text and media fields should always be used as the primary source.
 * If the link is internal we can fallback to the linked entity's teaser data.
 */
import type {
  ParagraphTeaserFragment,
  MediaImageFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: ParagraphTeaserFragment['title']
  node?: ParagraphTeaserFragment['node']
  image?: MediaImageFragment
  link?: ParagraphTeaserFragment['link']
  text?: ParagraphTeaserFragment['text']
}>()

const { isEditing, parentType } = defineBlokkli({
  bundle: 'teaser',
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    mockProps: (text) => {
      return {
        overrideTitle: text || 'Teaser Titel',
        link: {
          uri: {
            path: '/',
          },
        },
      }
    },
  },
})

const title = computed(() => {
  if (props.title) {
    return props.title
  }
  if (props.node) {
    if ('teaserTitle' in props.node && props.node.teaserTitle) {
      return props.node?.teaserTitle
    } else if ('title' in props.node && props.node.title) {
      return props.node.title
    }
  }
  return ''
})

const text = computed(() => {
  if (props.text) {
    return props.text
  }
  if (props.node && 'teaserText' in props.node) {
    return props.node?.teaserText
  }
  return ''
})

const media = computed(() => {
  if (props.image) {
    return props.image
  }
  if (props.node && 'teaserImage' in props.node) {
    return props.node?.teaserImage
  }
  return undefined
})

const url = computed(() => {
  if (props.link?.uri?.path) {
    return props.link?.uri.path
  } else if (props.node) {
    if ('price' in props.node && props.node.link?.uri?.path) {
      return props.node.link.uri.path
    } else if ('url' in props.node) {
      return props.node?.url?.path || ''
    }
  }
  return ''
})

const linkText = computed(() => props.link?.title)

defineOptions({
  name: 'ParagraphTeaser',
})
</script>
