<template>
  <span v-if="startDate && startDate === endDate" class="sr-only">
    <time v-if="startDate" :datetime="start">
      {{ startDatePrint
      }}<span v-if="timeFormatted">{{ ' ' }}{{ timeFormatted }}</span>
    </time>
  </span>
  <span v-else-if="startDate || endDate" class="sr-only">
    <time v-if="startDate" :datetime="start">
      <span>{{ $texts('accessibility.startDate', 'von') }}{{ ' ' }}</span>
      <span>
        {{ startDatePrint
        }}<span v-if="startTimePrint">{{ ' ' }}{{ startTimePrint }}</span>
      </span>
    </time>
    <time v-if="endDate" :datetime="end">
      <span v-if="startDate">{{ ' ' }}</span>
      <span>{{ $texts('accessibility.endDate', 'bis') }}{{ ' ' }}</span>
      <span>
        {{ endDatePrint
        }}<span v-if="endTimePrint">{{ ' ' }}{{ endTimePrint }}</span>
      </span>
    </time>
  </span>
  <div aria-hidden="true">{{ datePrint }}</div>
  <div v-if="timeFormatted" aria-hidden="true">
    {{ timeFormatted }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  startDate?: string
  endDate?: string
  startDatePrint?: string
  endDatePrint?: string
  startTime?: string
  endTime?: string
  startTimePrint?: string
  endTimePrint?: string
  datePrint?: string
  timestampStart?: string
}>()

const start = computed(() => {
  if (!props.startTime) {
    return props.startDate
  }

  return `${props.startDate} ${props.startTime}`
})

const end = computed(() => {
  if (!props.endTime) {
    return props.endDate
  }

  return `${props.endDate} ${props.endTime}`
})

const timeFormatted = computed(() => {
  if (props.startTimePrint && props.startTimePrint === props.endTimePrint) {
    return props.startTimePrint
  }

  if (!props.endTimePrint) {
    return props.startTimePrint
  }

  return `${props.startTimePrint} – ${props.endTimePrint}`
})

const { $texts } = useEasyTexts()
</script>
