import { default as indexI7bbRyvELUMeta } from "/builds/bazinga/mfg/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index6Ev8LmrRceMeta } from "/builds/bazinga/mfg/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as _91uuid_93YkcAF9SVpWMeta } from "/builds/bazinga/mfg/frontend/pages/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexwlfUhzQZl3Meta } from "/builds/bazinga/mfg/frontend/pages/calendar/index.vue?macro=true";
import { default as index2T0IdK0nFqMeta } from "/builds/bazinga/mfg/frontend/pages/contact/[..slug]/index.vue?macro=true";
import { default as indexMp9smXAafBMeta } from "/builds/bazinga/mfg/frontend/pages/events/[...slug]/index.vue?macro=true";
import { default as indexqRF9tJRGJhMeta } from "/builds/bazinga/mfg/frontend/pages/events/[id]/register/index.vue?macro=true";
import { default as indexpSwN0yyaYQMeta } from "/builds/bazinga/mfg/frontend/pages/events/index.vue?macro=true";
import { default as index5cRNqPpXJnMeta } from "/builds/bazinga/mfg/frontend/pages/events/series/[id]/index.vue?macro=true";
import { default as indexcTrAQqo3K6Meta } from "/builds/bazinga/mfg/frontend/pages/exhibition/[...slug]/index.vue?macro=true";
import { default as _91id_93JDiWDrOO8IMeta } from "/builds/bazinga/mfg/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93liqPQd507oMeta } from "/builds/bazinga/mfg/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as index1FqLiGXNC9Meta } from "/builds/bazinga/mfg/frontend/pages/index.vue?macro=true";
import { default as indexyMZUmzpL6IMeta } from "/builds/bazinga/mfg/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexKnQaferd5UMeta } from "/builds/bazinga/mfg/frontend/pages/search/index.vue?macro=true";
import { default as indexoTs9BIdrW1Meta } from "/builds/bazinga/mfg/frontend/pages/styleguide/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexI7bbRyvELUMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "article-detail",
    path: "/article/:slug(.*)*",
    meta: index6Ev8LmrRceMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/article/[...slug]/index.vue")
  },
  {
    name: "blokkli-library-item-uuid",
    path: "/blokkli/library-item/:uuid()",
    meta: _91uuid_93YkcAF9SVpWMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/blokkli/library-item/[uuid].vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    meta: indexwlfUhzQZl3Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/calendar/index.vue")
  },
  {
    name: "contact-..slug",
    path: "/contact/:..slug()",
    meta: index2T0IdK0nFqMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/contact/[..slug]/index.vue")
  },
  {
    name: "eventinstance-default",
    path: "/events/:slug(.*)*",
    meta: indexMp9smXAafBMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/[...slug]/index.vue")
  },
  {
    name: "event-register-form",
    path: "/events/:id()/register",
    meta: indexqRF9tJRGJhMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/[id]/register/index.vue")
  },
  {
    name: "admin-events",
    path: "/events",
    meta: indexpSwN0yyaYQMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/index.vue")
  },
  {
    name: "event-series-id",
    path: "/events/series/:id()",
    meta: index5cRNqPpXJnMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/events/series/[id]/index.vue")
  },
  {
    name: "exhibition-detail",
    path: "/exhibition/:slug(.*)*",
    meta: indexcTrAQqo3K6Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/exhibition/[...slug]/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93JDiWDrOO8IMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93liqPQd507oMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "homepage",
    path: "/",
    meta: index1FqLiGXNC9Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexyMZUmzpL6IMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexKnQaferd5UMeta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/search/index.vue")
  },
  {
    name: "styleguide-page",
    path: "/styleguide",
    meta: indexoTs9BIdrW1Meta || {},
    component: () => import("/builds/bazinga/mfg/frontend/pages/styleguide/index.vue")
  }
]