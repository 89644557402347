<template>
  <component :is="component" v-bind="componentProps" @click="navigate">
    <slot :is-external="isExternal" />
  </component>
</template>

<script lang="ts" setup>
import { getUrlExtension } from '#vuepal/helpers/url'
import { COUNTS_AS_INTERNAL_DOMAINS } from './DrupalLinkConstants'

const props = defineProps<{
  to?: string
  target?: string
  activeClass?: string
}>()

const EXTERNAL_URL_EXTENSIONS = ['jpg', 'png', 'svg', 'pdf']

const router = useRouter()
const route = useRoute()

const href = computed(() => {
  if (!props.to) {
    return ''
  }

  if (props.to.startsWith('www')) {
    return `https://${props.to}`
  }

  return props.to
})

const component = computed(() => (href.value ? 'a' : 'div'))
const urlExtension = computed(() => getUrlExtension(href.value) || '')

const countsAsInternal = computed(() =>
  href.value.match(COUNTS_AS_INTERNAL_DOMAINS),
)

const isExternal = computed(
  () =>
    (href.value.startsWith('http') && !countsAsInternal.value) ||
    href.value.startsWith('mailto:') ||
    href.value.startsWith('tel:') ||
    EXTERNAL_URL_EXTENSIONS.includes(urlExtension.value),
)

const isActive = computed(() =>
  route.path && href.value ? route.path.startsWith(href.value) : false,
)

const componentProps = computed<Record<string, string | string[]>>(() => {
  const componentProps: Record<string, any> = {
    class: [],
  }
  if (href.value) {
    componentProps.href = href.value

    if (props.target) {
      componentProps.target = props.target
    } else if (isExternal.value) {
      componentProps.target = '_blank'
    }
  }

  componentProps.class.push(isExternal.value ? 'is-external' : 'is-internal')

  if (isActive.value) {
    componentProps.class.push(props.activeClass || 'nuxt-link-exact-active')
  }

  return componentProps
})

// https://github.com/vuejs/router/blob/main/packages/router/src/RouterLink.ts#L375C1-L393C2
function guardEvent(e: MouseEvent) {
  // don't redirect with control keys
  if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return
  // don't redirect when preventDefault called
  if (e.defaultPrevented) return
  // don't redirect on right click
  if (e.button !== undefined && e.button !== 0) return
  // don't redirect if `target="_blank"`
  // @ts-expect-error getAttribute does exist
  if (e.currentTarget && e.currentTarget.getAttribute) {
    // @ts-expect-error getAttribute exists
    const target = e.currentTarget.getAttribute('target')
    if (/\b_blank\b/i.test(target)) return
  }
  // this may be a Weex event which doesn't have this method
  if (e.preventDefault) e.preventDefault()

  return true
}

function navigate(e: MouseEvent = {} as MouseEvent) {
  // the link should be handled by the browser
  // countsAsInternal are fully qualified URLs
  if (isExternal.value || countsAsInternal.value) {
    return
  }

  if (guardEvent(e)) {
    router.push(href.value)
  }
}
</script>
