<template>
  <section
    v-if="exhibitions?.length || isEditing"
    id="exhibition"
    class="mt-section mb-10"
  >
    <Container>
      <h2 id="matching-exhibition" class="h2 section-title">
        {{ $texts('event.related_exhibition', 'Passende Ausstellung') }}
      </h2>
      <div v-if="isEditing && !exhibitions?.length">
        Keine Ausstellung gefunden. Dieser Abschnitt wird nur während dem
        Editieren angezeigt.
      </div>
    </Container>
    <TeaserSlider
      v-if="exhibitions?.length"
      :id="uuid"
      :slides="exhibitions"
      aspect-ratio="exhibition"
    >
      <template #default="{ item }">
        <TeaserExhibition v-bind="item" />
      </template>
    </TeaserSlider>
  </section>
</template>

<script setup lang="ts">
import type { TeaserNodeExhibitionFragment } from '#graphql-operations'

const { isEditing, uuid } = defineBlokkliFragment({
  name: 'event_exhibition',
  label: 'Passende Ausstellung',
  description: 'Zeigt die passende Ausstellung einer Veranstaltung an.',
  globalOptions: ['showInAnchor'],
  editor: {
    previewWidth: 1280,
  },
})

const exhibitions = inject<ComputedRef<
  TeaserNodeExhibitionFragment[] | null | undefined
> | null>('event_exhibition', null)
</script>
