<template>
  <div class="bg-black pt-8 lg:pt-18 my-section">
    <Container>
      <h2 class="h2 text-white section-title">
        {{ $texts('header.target_group', 'Angebote für') }}
      </h2>
    </Container>

    <OrderedList
      v-if="groups"
      v-slot="{ item, isLast }"
      :list="groups"
      class="border-t border-t-stone-900"
    >
      <NuxtLink
        class="block hover:bg-stone-900 transition"
        :to="item.page?.url?.path"
        :class="{
          'border-b border-b-stone-900': !isLast,
        }"
      >
        <div class="container container-grid py-6 lg:py-9">
          <MediaImage
            v-if="item.page?.image"
            class="col-span-2"
            :image-style="imageStyle"
            hide-caption
            v-bind="item.page.image"
          />
          <div class="col-end-[-1] col-start-3">
            <h3 class="text-white h3">{{ item.label }}</h3>
          </div>
        </div>
      </NuxtLink>
    </OrderedList>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'

defineBlokkliFragment({
  name: 'target_groups',
  label: 'Zielgruppen',
  description: 'Listet die Links zu den Angeboten für Zielgruppen.',
})

const { maxWidthForCols } = useTailwind()

const { data: groups } = await useAsyncGraphqlQuery('targetGroupList', null, {
  transform: function (data) {
    const items = data.data.entityQuery.items
    if (!items?.length) {
      return []
    }

    return items.map((v) => (v && 'label' in v ? v : null)).filter(notNullish)
  },
  graphqlCaching: {
    client: true,
  },
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    default: 100,
    lg: maxWidthForCols('lg', 3),
  },
})
</script>
