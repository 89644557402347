<template>
  <div :id="id" class="container mt-section">
    <h2 class="h2 section-title">{{ title }}</h2>

    <OrderedList
      v-slot="{ item }"
      :list="locations"
      class="grid grid-cols-1 sm:grid-cols-3 gap-gap break-words"
    >
      <TermLocationTeaser v-bind="item" />
    </OrderedList>
  </div>
</template>

<script setup lang="ts">
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphLocationsFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  anchorTitle?: string
  locations?: ParagraphLocationsFragment['locations']
}>()

defineBlokkli({
  bundle: 'locations',
  globalOptions: ['showInAnchor'],
})

const id = computed(() => slugify(props.anchorTitle || props.title))
</script>
