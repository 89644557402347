<template>
  <DrupalLink
    :to="url"
    class="teaser-horizontal container container-grid mobile-only:gap-x-1 group group/link"
    :class="{
      'text-white': inverted,
    }"
  >
    <div class="grid-area-d typo-teaser-title font-bold pr-2 xs:pr-0">
      <FieldItemTypeDateRange v-bind="dates" />
    </div>

    <p v-if="tagline" class="grid-area-n typo-teaser-text leading-none">
      {{ tagline }}
    </p>

    <div class="grid-area-c">
      <div>
        <h3 class="typo-teaser-title font-bold text-balance mb-teaser">
          {{ title }}
        </h3>
        <div v-if="contentMapped.length || accessibilityItems?.length">
          <div
            v-for="(item, index) in contentMapped"
            :key="index"
            class="typo-teaser-text leading-none"
            v-html="item"
          />
          <OrderedList
            v-if="accessibilityItems"
            v-slot="{ item }"
            :list="accessibilityItems"
            class="flex gap-2"
          >
            <MediaIcon v-bind="item.icon" aria-hidden class="size-6" />
            <span class="sr-only">{{ item.name }}</span>
          </OrderedList>
        </div>
      </div>

      <slot />
    </div>

    <div v-if="image" class="grid-area-m hidden xs:block overflow-hidden">
      <MediaImage
        hide-caption
        v-bind="image"
        :image-style="imageStyle"
        :preload="false"
        img-class="md:group-hover:scale-105 transition ease-swing"
      />
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type {
  MediaImageFragment,
  TermWithIconFragment,
  EventTeaserFragment,
  NodeExhibitionFragment,
} from '#graphql-operations'

const props = defineProps<{
  tagline?: string
  title?: string
  url?: string
  dates?: EventTeaserFragment['dates'] | NodeExhibitionFragment['dates']
  content?:
    | string
    | string[]
    | Array<string | null | undefined | { label?: string; name?: string }[]>
  image?: MediaImageFragment
  inverted?: boolean
  accessibility?: TermWithIconFragment[]
}>()

const contentMapped = computed<string[]>(() => {
  if (!props.content) {
    return []
  }

  if (typeof props.content === 'string') {
    return props.content.trim() ? [props.content] : []
  }

  return props.content
    .filter(Boolean)
    .map((item) => {
      if (Array.isArray(item)) {
        return item
          .map((v) => (v.label || v.name)?.trim())
          .filter(Boolean)
          .join(', ')
      }
      return item?.trim()
    })
    .filter(notNullish)
    .filter(Boolean)
})

// @TODO: Show zielgruppe
// Big date on.

const { maxWidthForCols, getAspectRatio } = useTailwind()

const aspectRatio = computed(() =>
  props.inverted ? getAspectRatio('exhibition').number : 1,
)

const imageStyle = computed(() =>
  defineImageStyle({
    type: 'sizes',
    aspectRatio: aspectRatio.value,
    sizes: {
      sm: maxWidthForCols('sm', 3),
      lg: maxWidthForCols('lg', 3),
    },
  }),
)

const accessibilityItems = computed(() =>
  props.accessibility
    ?.map((v) => {
      if (v.icon && v.name) {
        return v
      }

      return null
    })
    .filter(notNullish),
)

defineOptions({
  name: 'TeaserHorizontal',
})
</script>

<style lang="postcss">
.teaser-horizontal {
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'D D D D D D D D D D D D'
    'N N N N N N N N N N N N'
    'C C C C C C C C C C C C';
  @screen xxs {
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'D D D D N N N N N N N N'
      'D D D D C C C C C C C C';
  }
  @screen xs {
    grid-template-areas:
      '. . . N N N N N N . M M'
      'D D D C C C C C C . M M';
  }
  @screen lg {
    grid-template-areas:
      '. . . N N N N N N . M M'
      'D D D C C C C C C . M M';
  }

  @screen xl {
    grid-template-areas:
      '. . . N N N N N N . M M'
      'D D D C C C C C . . M M';
  }
}
</style>
