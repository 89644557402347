<template>
  <div :id="anchorId" :class="containerClassList">
    <h2
      v-if="title"
      v-blokkli-editable:field_title
      class="container h2"
      :class="{
        'mb-6': options.isGrid,
      }"
    >
      {{ title }}
    </h2>
    <BlokkliField
      :list="paragraphs"
      name="field_content"
      :field-list-type="fieldListType"
      :class="{
        'container container-grid gap-y-gap': options.isGrid,
      }"
    />

    <div v-if="globalCaption || globalCopyright" class="container mt-10">
      <Caption
        :caption="globalCaption"
        :copyright="globalCopyright"
        tag="div"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphImageListFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImageList',
})

const props = defineProps<{
  title?: string
  anchorTitle?: string
  globalCaption?: string
  globalCopyright?: string
  paragraphs: ParagraphImageListFragment['paragraphs']
}>()

const { options } = defineBlokkli({
  bundle: 'image_list',
  globalOptions: ['showInAnchor'],
  options: {
    isGrid: {
      type: 'checkbox',
      label: 'Grid View',
      default: false,
    },
    spacing: {
      // this is different than the global spacing option
      type: 'radios',
      label: 'Spacing',
      default: 'default',
      displayAs: 'icons',
      options: {
        default: {
          label: 'default',
          icon: 'icon-blokkli-option-large-spacing',
        },
        compact: {
          label: 'compact spacing',
          icon: 'icon-blokkli-option-no-spacing',
        },
        small: {
          label: 'small spacing',
          icon: 'icon-blokkli-option-small-spacing',
        },
      },
    },
  },
  editor: {
    determineVisibleOptions: function (ctx) {
      if (ctx.options.isGrid === true) {
        return ['showInAnchor', 'isGrid', 'spacing']
      }
      return ['showInAnchor', 'isGrid']
    },
  },
})

const anchorId = computed(() => slugify(props.anchorTitle || props.title))

const fieldListType = computed(() => {
  return options.value.isGrid ? 'grid' : 'default'
})

const containerClassList = computed(() => {
  const classList: string[] = []

  if (!options.value.isGrid || options.value.spacing === 'default') {
    classList.push('mt-section')
  } else if (options.value.spacing === 'small') {
    classList.push('mt-6')
  }

  return classList
})
</script>
