<template>
  <footer class="bg-black text-white">
    <PageMenuLinks>
      <Container class="pt-12 lg:pt-19">
        <LogoMuseumHome class="mb-10 text-white hover:text-stone-700" />
        <SocialmediaMenu
          :instagram="
            $texts(
              'socialmedia.museum_instagram',
              'https://www.instagram.com/museumgestaltung/',
            )
          "
          :linkedin="
            $texts(
              'socialmedia.museum_linkedin',
              'https://www.linkedin.com/company/museumgestaltung',
            )
          "
          :youtube="
            $texts(
              'socialmedia.museum_youtube',
              'https://www.youtube.com/@mfgzh',
            )
          "
          :facebook="
            $texts(
              'socialmedia.museum_facebook',
              'https://facebook.com/museumgestaltung',
            )
          "
          :tiktok="
            $texts(
              'socialmedia.museum_tiktok',
              'https://www.tiktok.com/@museumgestaltung',
            )
          "
          :threads="
            $texts(
              'socialmedia.museum_threads',
              'https://www.threads.net/@museumgestaltung',
            )
          "
        />
      </Container>

      <PageMenuGroup :title="$texts('footer.meta', 'Entdecken & Unterstützen')">
        <FooterMenu />
      </PageMenuGroup>

      <PageMenuGroup
        :title="
          $texts('footer.zhdk', 'Das Museum der Zürcher Hochschule der Künste')
        "
      >
        <div class="border-y-1 border-stone-950">
          <a
            href="https://www.zhdk.ch"
            target="_blank"
            class="block page-menu-link"
          >
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_zhdk',
                  'Zürcher Hochschule der Künste Home page.',
                )
              }}
            </span>
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_external',
                  'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
                )
              }}
            </span>
            <Container class="py-5">
              <SpriteSymbol
                viewBox="0 0 210.2 68.9"
                class="w-full max-w-[396px]"
                name="zhdk"
              />
            </Container>
          </a>
        </div>
      </PageMenuGroup>

      <PageMenuGroup
        :title="$texts('footer.support', 'Mit freundlicher Unterstützung')"
      >
        <div class="border-y-1 border-stone-950">
          <a
            href="https://www.bak.admin.ch/bak/de/home.html"
            target="_blank"
            class="block page-menu-link"
          >
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_bak',
                  'Bundesamt für Kultur Homepage.',
                )
              }}
            </span>
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_external',
                  'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
                )
              }}
            </span>
            <Container class="py-5">
              <SpriteSymbol
                viewBox="0 0 210.2 68.9"
                class="w-full max-w-[396px]"
                :name="bakLogo"
              />
            </Container>
          </a>
        </div>
      </PageMenuGroup>
      <Container class="pb-12 lg:pb-19">
        <FooterMetaMenu />
      </Container>
    </PageMenuLinks>
  </footer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const language = useCurrentLanguage()

const bakLogo = computed(() => {
  switch (language.value) {
    case 'fr':
      return 'bak-fr'
    case 'en':
      return 'bak-en'
    default:
      return 'bak-de'
  }
})

const { $texts } = useNuxtApp()
</script>
