<template>
  <NuxtLink class="flex items-center" :to="'/' + language">
    <span class="sr-only">
      {{ $texts('accessibility.go_to_homepage', 'Zur Startseite gehen') }}
    </span>
    <LogoPavillon />
  </NuxtLink>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()
</script>
