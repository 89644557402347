<template>
  <section :id="id" :class="title ? 'mt-section' : 'mt-spacer'">
    <h2
      v-if="title"
      v-blokkli-editable:field_title
      class="h2 container section-title"
    >
      {{ title }}
    </h2>
    <dl>
      <VisitorTableRow
        v-for="(item, index) in tableData"
        :key="'provided_' + index"
        :title="item.title"
        :value="item.value"
      />
      <VisitorTableRow
        v-for="(row, index) in validRows"
        :key="'row_' + index"
        :title="row.title"
        :value="[row.text]"
      />
    </dl>
  </section>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import { notNullish } from '@vueuse/core'
import type {
  FieldItemTypeTableRowFragment,
  ParagraphVisitorTableFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: string
  anchorTitle?: string
  rows?: ParagraphVisitorTableFragment['rows']
}>()

defineBlokkli({
  bundle: 'visitor_table',
  globalOptions: ['showInAnchor'],
})

const id = computed(() => slugify(props.anchorTitle || props.title))

const validRows = computed<Required<FieldItemTypeTableRowFragment>[]>(() =>
  (props.rows || [])
    .map((v) => {
      if (!v.text || !v.title) {
        return null
      }

      return {
        text: v.text,
        title: v.title,
      }
    })
    .filter(notNullish),
)

const tableData = useVisitorTableData()
</script>
