<template>
  <address v-if="addressLine1 || locality || postalCode" class="not-italic">
    <div v-if="addressLine1">{{ addressLine1 }}</div>
    <div v-if="addressLine2">{{ addressLine2 }}</div>
    <div v-if="postalCode && locality">
      <span>{{ postalCode }} {{ locality }}</span>
    </div>
    <div v-else-if="postalCode || locality">
      <span v-if="postalCode">{{ postalCode }}</span>
      <span v-if="locality">{{ locality }}</span>
    </div>

    <a
      v-if="mapsLink"
      class="link-underline text-base font-regular sm:text-lg-prose md:text-xl-prose"
      target="_blank"
      rel="noreferrer"
      :href="mapsLink"
    >
      {{ $texts('location.map_link', 'Zu Google Maps')
      }}<span class="sr-only">
        {{
          $texts(
            'accessibility.link_external',
            'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
          )
        }}
      </span>
    </a>
  </address>
</template>

<script setup lang="ts">
const props = defineProps<{
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  locality?: string
  countryCode?: string
  hideMapsLink?: boolean
}>()

const mapsLink = computed(() => {
  if (
    !props.hideMapsLink &&
    props.addressLine1 &&
    props.postalCode &&
    props.countryCode
  ) {
    return `https://www.google.com/maps/search/?api=1&query=${props.addressLine1},${props.postalCode}+${props.locality},+${props.countryCode}`
  }
  return ''
})
</script>

<style lang="postcss">
/* comma separated inline address */
address.inline {
  div {
    display: inline;
  }
  div + div::before {
    content: ', ';
  }
}
</style>
