<template>
  <Container class="mt-6 lg:mt-9 paragraph-button">
    <DrupalLink
      v-slot="{ isExternal }"
      :to="link?.uri?.path"
      class="button -ml-[3px] is-link"
      :class="buttonClassList"
    >
      {{ label }}
      <span v-if="isExternal" class="sr-only">
        {{
          $texts(
            'accessibility.link_external',
            'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
          )
        }}
      </span>
    </DrupalLink>
  </Container>
</template>

<script lang="ts" setup>
import type { ParagraphButtonFragment } from '#graphql-operations'

defineProps<{
  label: ParagraphButtonFragment['label']
  link: ParagraphButtonFragment['link']
}>()

const { options } = defineBlokkli({
  bundle: 'button',
  editor: {
    editTitle: (el) => el.textContent,
  },
  options: {
    buttonType: {
      type: 'radios',
      label: 'Button type',
      default: 'primary',
      options: {
        primary: 'Primär',
        secondary: 'Sekundär',
      },
    },
  },
})

const buttonClassList = computed(() => {
  const classList = []

  if (options.value.buttonType === 'secondary') {
    classList.push('is-secondary')
  }

  return classList
})
</script>

<style lang="postcss">
.paragraph-button + .paragraph-button {
  @apply mt-2;
}
</style>
