<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <Container>
      <PageTitle
        :entity="entity"
        :title="title"
        :class="{ 'sr-only': hideTitle }"
      />
    </Container>

    <BlokkliField
      v-slot="{ items }"
      :list="paragraphs"
      :allowed-fragments="['target_groups']"
      name="field_paragraphs"
      class="page-paragraphs"
    >
      <PageHero
        :entity="entity"
        :hero="hero"
        :hero-video="heroVideo"
        :has-title="!hideTitle"
        class="mb-8 lg:mb-14"
      >
        <InPageNavigation
          v-if="showAnchorNavigation"
          :paragraphs="items"
          :class="{
            'absolute bottom-4 md:bottom-10 w-full': hero || heroVideo,
          }"
        />
      </PageHero>
    </BlokkliField>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid?: string
  title?: string
  lead?: string
  showAnchorNavigation?: boolean
  hero?: NodePageFragment['hero']
  heroVideo?: NodePageFragment['heroVideo']
  paragraphs?: NodePageFragment['paragraphs']
  locationAddresses?: NodePageFragment['locationAddresses']
  body?: string
  hideTitle?: boolean
  blokkliProps: NodePageFragment['blokkliProps']
}>()

defineVisitorTableData((data) => {
  data.add(
    'address',
    props.locationAddresses?.map((v) => v.adddress),
  )
})

defineOptions({
  name: 'NodePage',
})
</script>
