<template>
  <Accordion
    :title="mappedTitle"
    class="border-b border-b-stone-100"
    :icon="icon"
    :always-open="isEditing"
    :class="{
      'border-t border-t-stone-100 mt-6': isFirstAccordion,
      'mb-6': isLastAccordion,
    }"
  >
    <template #title>
      <span v-blokkli-editable:field_title>{{ title }}</span>
    </template>
    <template #icon>
      <MediaIcon
        v-if="icon"
        v-blokkli-droppable:field_icon
        v-bind="icon"
        aria-hidden
        class="aspect-square size-6"
      />
    </template>
    <BlokkliField :list="paragraphs" name="field_content" />
  </Accordion>
</template>

<script lang="ts" setup>
import type { FieldListItemTyped } from '#blokkli/generated-types'
import type { ParagraphAccordeonFragment } from '#graphql-operations'

const props = defineProps<{
  title: ParagraphAccordeonFragment['title']
  icon: ParagraphAccordeonFragment['icon']
  paragraphs: ParagraphAccordeonFragment['paragraphs']

  /**
   * Force the accordion to be always open.
   *
   * We mostly use this in the editor, so that the contents of the accordion
   * are visible.
   */
  alwaysOpen?: boolean
}>()

const { isEditing, index, siblings } = defineBlokkli({
  bundle: 'accordeon',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
    addBehaviour: 'no-form',
  },
})

function isNotAccordeon(v?: FieldListItemTyped): boolean {
  return (v?.props?.libraryItem?.block?.bundle || v?.bundle) !== 'accordeon'
}

const isFirstAccordion = computed(() =>
  isNotAccordeon(siblings.value[index.value - 1]),
)

const isLastAccordion = computed(() =>
  isNotAccordeon(siblings.value[index.value + 1]),
)

const { $texts } = useEasyTexts()

const mappedTitle = computed(() => {
  return props.title || $texts('button_learn_more', 'Mehr erfahren')
})
</script>
