<template>
  <div class="bk-main-canvas relative">
    <ClientOnly>
      <LazyAdminBar v-if="drupalUser.accessToolbar && !isEditing" />
    </ClientOnly>

    <PageMenuHeaderPavillon v-if="isPavillon" />
    <PageMenuHeaderMuseum v-else />

    <div>
      <ClientOnly>
        <DrupalMessages v-if="!isEditing" />
      </ClientOnly>

      <NuxtPage />
    </div>

    <PageMenuFooterPavillon v-if="isPavillon" />
    <PageMenuFooterMuseum v-else />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = await useDrupalUser()
const language = useCurrentLanguage()
const { isPavillon } = useDomain()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

function getFavicons() {
  const folder = isPavillon ? 'pavillon' : 'museum'

  return [
    {
      rel: 'apple-touch-icon',
      href: `/favicon/${folder}/favicon_180.png`,
      sizes: '180x180',
      hid: 'icon-180',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicon/${folder}/favicon_32.png`,
      sizes: '32x32',
      hid: 'icon-32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicon/${folder}/favicon_192.png`,
      sizes: '192x192',
      hid: 'icon-192',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicon/${folder}/favicon_512.png`,
      sizes: '512x512',
      hid: 'icon-512',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicon/${folder}/favicon_16.png`,
      sizes: '16x16',
      hid: 'icon-16',
    },
    {
      rel: 'manifest',
      href: `/favicon/${folder}/manifest.json`,
      hid: 'manifest',
    },
    {
      rel: 'shortcut icon',
      href: `/favicon/${folder}/favicon.ico`,
      hid: 'shortcut-icon',
    },
  ]
}

useHead({
  link: getFavicons(),
  htmlAttrs: {
    lang: language.value,
  },
})
</script>

<style lang="postcss">
.page-enter-active,
.page-leave-active {
  @apply transition duration-150;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}
.page-enter-from,
.page-leave-to {
  @apply opacity-0;
}

.page-paragraphs {
  @apply mb-20;
}

.bk-field-list {
  /* Target the first paragraph that defines a section margin. */
  > .my-section:first-child,
  > .mt-section:first-child {
    /* Reduce the margin to the top hero image. */
    @apply mt-4 md:mt-10;
  }
}
</style>
