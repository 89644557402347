import type {
  RegistrantType,
  RegistrationStateFragment,
} from '#graphql-operations'

type UseEventRegistration = {
  registrantType: ComputedRef<RegistrantType | undefined>
  maxSeatsPerRegistration: ComputedRef<number>
  registerUrl: ComputedRef<string | undefined>
  registrationOpen: ComputedRef<boolean>
  registrationEnabled: ComputedRef<boolean>
  availableSeats: ComputedRef<number>
  showBookedOut: ComputedRef<boolean>
}

export default function (
  providedState:
    | RegistrationStateFragment
    | ComputedRef<
        | RegistrationStateFragment
        | { registrationState?: RegistrationStateFragment }
        | null
        | undefined
      >
    | undefined
    | null,
): UseEventRegistration {
  const state = computed<RegistrationStateFragment | null | undefined>(() => {
    if (!providedState) {
      return
    }
    const v = unref(providedState)
    if (!v) {
      return
    }
    if ('registrationState' in v) {
      return v.registrationState
    } else if ('registrantType' in v) {
      return v
    }
  })

  const availableSeats = computed(() => state.value?.availableSeats || 0)

  const registrationEnabled = computed(() => !!state.value?.registrationEnabled)
  const registrationOpen = computed(() => !!state.value?.registrationOpen)

  const maxSeatsPerRegistration = computed(
    () => state.value?.maxSeatsPerRegistration || 0,
  )

  const showBookedOut = computed<boolean>(() => {
    // If registration is not enabled generally, we don't display a "booked out"
    // message.
    if (!registrationEnabled.value) {
      return false
    }

    // There is no limit to the capacity, therefore it can't be booked out.
    if (state.value?.capacity === 0) {
      return false
    }

    // If there are only 0 available seats, it is booked out.
    return availableSeats.value === 0
  })

  const registerUrl = computed(() => state.value?.registerUrl)

  const registrantType = computed(() => state.value?.registrantType)

  return {
    registrantType,
    availableSeats,
    maxSeatsPerRegistration,
    registrationEnabled,
    registerUrl,
    showBookedOut,
    registrationOpen,
  }
}
