<template>
  <nav
    :aria-label="
      $texts('accessibility.footer_navigation', 'Fußzeilennavigation')
    "
  >
    <ul>
      <li
        v-for="(link, index) in links"
        :key="`global_${index}`"
        class="border-b-1 first:border-t-1 border-stone-950"
      >
        <DrupalLink
          :to="link.link?.url?.path"
          class="font-bold py-5 block page-menu-link typo-paragraph"
          active-class="active"
        >
          <Container>
            {{ link.link.label }}
          </Container>
        </DrupalLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const initData: Ref<InitData> = await useInitData()

const links = computed(() => {
  return initData.value.footerMenuLinks
})
</script>
