import type {
  CalendarSearchFacetInput,
  ParagraphDynamicListEventResultFilterFragment,
} from '#graphql-operations'
import { notNullish } from '@vueuse/core'
import type { RouteLocationNormalizedLoadedGeneric } from 'vue-router'

// The prefix for query parameter keys that are facets.
const FACET_PREFIX = 'f_'
// The string used to separate multiple terms.
const TERMS_SEPARATOR = '--'

type PossibleFacetInput =
  | CalendarSearchFacetInput
  | ParagraphDynamicListEventResultFilterFragment

type UseCalendar = {
  /**
   * Build the selected facets from the current route object.
   */
  buildFacets: (
    route: RouteLocationNormalizedLoadedGeneric,
  ) => CalendarSearchFacetInput[]

  /**
   * Build a route query for the calendar search.
   */
  buildQuery: (
    facets?: PossibleFacetInput[],
    dateFrom?: string,
    dateTo?: string,
  ) => Record<string, string | undefined>
}

export default function (): UseCalendar {
  function buildFacets(route: RouteLocationNormalizedLoadedGeneric) {
    return Object.entries(route.query)
      .map(([field, value]) => {
        if (!field.startsWith(FACET_PREFIX) || typeof value !== 'string') {
          return null
        }

        const terms = value
          .split(TERMS_SEPARATOR)
          .map((v) => v.trim())
          .filter(Boolean) // Remove empty values.

        if (!terms.length) {
          return null
        }

        return {
          field: field.replace(FACET_PREFIX, ''),
          terms,
        }
      })
      .filter(notNullish)
  }

  function buildQuery(
    facets?: PossibleFacetInput[],
    dateFrom?: string,
    dateTo?: string,
  ): Record<string, string | undefined> {
    const updatedQuery: Record<string, string | undefined> = {
      dateFrom: dateFrom || undefined,
      dateTo: dateTo || undefined,
    }
    facets?.forEach((facet) => {
      if (facet.terms?.length) {
        updatedQuery[FACET_PREFIX + facet.field] =
          facet.terms.join(TERMS_SEPARATOR)
      }
    })

    return updatedQuery
  }

  return {
    buildFacets,
    buildQuery,
  }
}
