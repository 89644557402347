
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'arrow-down': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/arrow-down.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 78 96","id":"arrow-down"} },
  'arrow-left': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/arrow-left.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 96 96","id":"arrow-left"} },
  'arrow-right': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/arrow-right.svg?raw').then(v => v.default), attributes: {"id":"arrow-right","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 96 96"} },
  'arrow-up': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/arrow-up.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 78 96","id":"arrow-up"} },
  'calendar': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/calendar.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 96 96","id":"calendar"} },
  'caron-down': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/caron-down.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 90 96","id":"caron-down"} },
  'caron-left': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/caron-left.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 96","id":"caron-left"} },
  'caron-right': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/caron-right.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 96","id":"caron-right"} },
  'caron-up': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/caron-up.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 90 96","id":"caron-up"} },
  'check': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/check.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 10 8","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check"} },
  'close': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/close.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 78 96","id":"close"} },
  'external-link': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/external-link.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 80 96","id":"external-link"} },
  'filter': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/filter.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 96 96","id":"filter"} },
  'info': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/info.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 102 96","id":"info"} },
  'bak-de': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/logo/bak-de.svg?raw').then(v => v.default), attributes: {"enable-background":"new 0 0 210.2 68.9","viewBox":"0 0 210.2 68.9","xmlns":"http://www.w3.org/2000/svg","id":"bak-de"} },
  'bak-en': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/logo/bak-en.svg?raw').then(v => v.default), attributes: {"enable-background":"new 0 0 210.2 68.9","viewBox":"0 0 210.2 68.9","xmlns":"http://www.w3.org/2000/svg","id":"bak-en"} },
  'bak-fr': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/logo/bak-fr.svg?raw').then(v => v.default), attributes: {"enable-background":"new 0 0 210.2 68.9","viewBox":"0 0 210.2 68.9","xmlns":"http://www.w3.org/2000/svg","id":"bak-fr"} },
  'zh-de': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/logo/zh-de.svg?raw').then(v => v.default), attributes: {"fill":"none","height":"44","viewBox":"0 0 183 44","width":"183","xmlns":"http://www.w3.org/2000/svg","id":"zh-de"} },
  'zhdk': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/logo/zhdk.svg?raw').then(v => v.default), attributes: {"enable-background":"new 0 0 210.2 68.9","viewBox":"0 0 210.2 68.9","xmlns":"http://www.w3.org/2000/svg","id":"zhdk"} },
  'menu': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/menu.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 96 96","id":"menu"} },
  'play': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"viewBox":"44.9 44.9 83.2 83.2","fill":"none","xmlns":"http://www.w3.org/2000/svg","style":"filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));","id":"play"} },
  'search': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 84 96","id":"search"} },
  'facebook': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/facebook.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"facebook"} },
  'instagram': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/instagram.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"instagram"} },
  'linkedin': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/linkedin.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"linkedin"} },
  'mastodon': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/mastodon.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"mastodon"} },
  'threads': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/threads.svg?raw').then(v => v.default), attributes: {"fill":"none","height":"25","viewBox":"0 0 25 25","width":"25","xmlns":"http://www.w3.org/2000/svg","id":"threads"} },
  'tiktok': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/tiktok.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"tiktok"} },
  'youtube': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/social-media/youtube.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"youtube"} },
  'warning': { import: () => import('/builds/bazinga/mfg/frontend/assets/symbols/warning.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 114 96","id":"warning"} },
}
  