<template>
  <div class="bk-main-canvas relative">
    <NuxtErrorBoundary>
      <PageMenuHeaderPavillon v-if="isPavillon" />
      <PageMenuHeaderMuseum v-else />
      <template #error>
        <header>
          <div class="bg-white h-header">
            <Container class="flex justify-between items-center h-full">
              <div class="mr-auto">
                <NuxtLink class="block" to="/">
                  <LogoPavillon v-if="isPavillon" />
                  <LogoMuseum v-else />
                </NuxtLink>
              </div>
            </Container>
          </div>
        </header>
      </template>
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <div>
        <div v-if="code === 404">
          <PageNotFound />
        </div>
        <Container v-else>
          <PageTitle :title="title" />
          <p class="typo-paragraph">
            {{ lead }}
          </p>
          <div class="my-6 lg:my-9">
            <NuxtLink class="button" to="/">Home</NuxtLink>
          </div>

          <p class="typo-paragraph opacity-10 mb-10 text-xs">
            {{ error?.message || error?.statusMessage || 'no message' }}
          </p>
        </Container>
      </div>
      <template #error>
        <Container>
          <h1 class="h1 text-balance">
            {{ error?.statusCode || 'no status code' }}
          </h1>
          <p class="typo-paragraph opacity-10 mb-10 text-xs">
            {{ error?.message || error?.statusMessage || 'no message' }}
          </p>
        </Container>
      </template>
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <PageMenuFooterPavillon v-if="isPavillon" />
      <PageMenuFooterMuseum v-else />
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const { isPavillon } = useDomain()

const props = defineProps<{
  error?: NuxtError
}>()

type ErrorData = {
  title: string
  lead?: string
}

function defineError(title: string, lead?: string): ErrorData {
  return {
    title,
    lead,
  }
}

const ERRORS: Record<number, ErrorData> = {
  400: defineError(
    'Ungültige Anfrage',
    'Die Anfrage konnte aufgrund fehlerhafter Syntax nicht verarbeitet werden.',
  ),
  403: defineError(
    'Zugriff verweigert',
    'Sie haben keine Berechtigung, auf diese Ressource zuzugreifen.',
  ),
  404: defineError(
    'Seite nicht gefunden',
    'Die gewünschte Seite wurde leider nicht gefunden.',
  ),
}

// see all error properties in the console
console.error('error', props.error)

const code = computed(() => props.error?.statusCode || 500)

const errorData = computed<ErrorData | undefined>(() => {
  return ERRORS[code.value]
})

const title = computed(() => {
  return code.value + ': ' + (errorData.value?.title || 'Interner Serverfehler')
})

const lead = computed(() => {
  return errorData.value?.lead || ''
})
</script>
