// IMPORTANT: Do not import from tailwind.config, because that will bundle
// the entire config (including plugin code) in the client bundle.

import { gap } from '~/components/ContainerGrid/config'
import {
  aspectRatios,
  breakpoints,
  maxColWidth,
  rokkaMax,
  type AspectRatio,
  type AspectRatioConfig,
  type Breakpoint,
} from '~/tailwind'

function maxWidthForCols(
  breakpoint: Breakpoint | 'default',
  numberOfCols: number,
): number {
  if (breakpoint === 'xl' || breakpoint === 'xxl') {
    throw new Error(
      "Breakpoint 'xl' is not supported in maxWidthForCols function. A grid is not used for full-width containers. Take rokkaMax",
    )
  }

  return Math.ceil(
    maxColWidth[breakpoint] * numberOfCols + gap * (numberOfCols - 1),
  )
}

type UseTailwind = {
  breakpoints: Readonly<Record<Breakpoint, number>>
  rokkaMax: Readonly<number>
  maxWidthForCols: (
    breakpoint: Breakpoint | 'default',
    numberOfCols: number,
  ) => number
  getAspectRatio: (ratio: AspectRatio) => AspectRatioConfig
}

export default function (): UseTailwind {
  function getAspectRatio(ratio: AspectRatio): AspectRatioConfig {
    return aspectRatios[ratio]
  }
  return {
    breakpoints,
    maxWidthForCols,
    rokkaMax,
    getAspectRatio,
  }
}
