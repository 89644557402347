<template>
  <div class="container container-grid size-aware-container">
    <slot />
  </div>
</template>

<style lang="postcss">
.size-aware-full {
  /* Makes sure there is enough room to properly display an image or video with this max size. */
  @media (max-aspect-ratio: 16/10), (min-width: 2520px) {
    @apply max-w-screen-xxl;
  }
}
</style>
