<template>
  <ContainerGrid v-if="value" class="container my-3 md:my-5 lg:my-8">
    <dt
      class="col-start-1 col-span-full sm:col-span-4 md:col-span-3 text-base-prose sm:text-lg-prose md:text-xl-prose font-medium text-balance"
    >
      {{ titleMapped }}
    </dt>
    <dd
      class="col-start-1 sm:col-start-5 col-end-[-1] md:col-start-4 lg:col-end-[-3]"
    >
      <VisitorTableRowValue v-if="isSingle" :value="value[0]" />
      <OrderedList
        v-else
        v-slot="{ item }"
        :list="value"
        :class="{
          'grid gap-4 md:gap-6 lg:gap-8': title === 'address',
        }"
      >
        <VisitorTableRowValue :value="item" />
      </OrderedList>
    </dd>
  </ContainerGrid>
</template>

<script setup lang="ts">
import type { VisitorTableTitle, VisitorTableValue } from './types'

const props = defineProps<{
  title: VisitorTableTitle | string
  value: VisitorTableValue[]
  large?: boolean
  bold?: boolean
}>()

const { $texts } = useEasyTexts()
const isSingle = computed(() => props.value.length <= 1)

const titleMapped = computed(() => {
  switch (props.title) {
    case 'accessibility':
      return $texts('info.accessibility', 'Barrierefreiheit')
    case 'availability_count':
      return $texts('info.availability_count', 'Freie Plätze')
    case 'price':
      return $texts('info.price', 'Preis')
    case 'info':
      return $texts('info.visitorInformation', 'Info')
    case 'address':
      return $texts('info.address', 'Adresse')
    case 'date':
      return $texts('info.date', 'Datum')
    case 'target_group':
      return $texts('info.targetGroup', 'Für')
    case 'languages':
      return $texts('info.languages', 'Sprachen')
  }

  return props.title
})

defineOptions({
  name: 'VisitorTableRow',
})
</script>
