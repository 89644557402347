import type { FieldListItem } from '#blokkli/types'
import { slugify } from '#vuepal/helpers/url'
import type {
  ParagraphDynamicListEventFragment,
  ParagraphFragment,
} from '#graphql-operations'

type FieldListItemTyped = FieldListItem & { props?: ParagraphFragment }

type AnchorLinkDefiner = {
  add: (
    label: string,
    id: string,
    condition?: ComputedRef<boolean>,
  ) => AnchorLinkDefiner

  addParagraphs: (
    list?: FieldListItemTyped[] | undefined | null,
    conditions?: {
      event_exhibition?: ComputedRef<any>
    },
  ) => AnchorLinkDefiner
}

type AnchorLink = {
  label: string
  id: string
}

function isTruthyCheckbox(v: unknown): boolean {
  return v === 1 || v === true || v === '1' || v === 'true'
}

/**
 * Determine which title to use for the anchor link.
 */
function getAnchorTitle(item?: FieldListItemTyped) {
  if (!item) {
    return
  }

  // Reusable paragraph: We need to access the actual paragraph that was made
  // to be reusable.
  if (item.bundle === 'from_library') {
    return getAnchorTitle(item.props?.libraryItem?.block)
  }

  return item.props?.anchorTitle || item.props?.title
}

/**
 * Composable to define anchor links.
 */
export function defineAnchorLinks(
  cb: (definer: AnchorLinkDefiner) => void,
): ComputedRef<AnchorLink[]> {
  const { $texts } = useEasyTexts()

  return computed(function () {
    // Collect the defined data.
    const links: AnchorLink[] = []

    const definer: AnchorLinkDefiner = {
      add: function (
        label: string,
        id: string,
        condition?: ComputedRef<boolean>,
      ) {
        if (condition && condition.value === false) {
          return this
        }
        links.push({ label, id })
        return this
      },

      addParagraphs: function (list, conditions) {
        if (!list) {
          return this
        }

        list.forEach((item) => {
          // Check whether the paragraph should be added as an anchor link.
          if (!isTruthyCheckbox(item.options?.showInAnchor)) {
            return
          }

          if (
            item.bundle === 'blokkli_fragment' &&
            item.props?.name === 'event_exhibition'
          ) {
            if (conditions && conditions.event_exhibition?.value) {
              this.add(
                $texts('event.related_exhibition', 'Passende Ausstellung'),
                'exhibition',
              )
            }
            return
          }

          // If the event list paragraph does not display any events, hide it.
          if (item.bundle === 'dynamic_list_event') {
            // We cast the type explicitly so we properly type check if the
            // fragment changes.
            const props = item.props as
              | ParagraphDynamicListEventFragment
              | undefined
            // No props, something is broken.
            if (!props) {
              return
            }

            // No events: Don't display an anchor.
            if (!props.eventsList?.events?.length) {
              return
            }
          }

          // Determine the anchor title.
          const label = getAnchorTitle(item)
          if (!label) {
            return
          }

          const id = slugify(label)
          this.add(label, id)
        })

        return this
      },
    }

    cb(definer)

    return links
  })
}
