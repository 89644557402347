
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'sm': 768,
  'md': 1024,
  'lg': 1480,
  'default': 0,
  'xxs': 340,
  'xs': 520,
  'xl': 1920,
  'xxl': 2560
} as const

export type Viewport = keyof typeof VIEWPORTS