<template>
  <section>
    <ContainerGrid class="container">
      <button
        :id="idButton"
        type="button"
        class="col-span-full flex py-4 font-medium md:text-xl items-center hover:text-stone-700 min-h-16 text-left gap-2"
        :aria-expanded="isOpen"
        :aria-controls="idContent"
        @click.prevent="isOpenByClick = !isOpenByClick"
      >
        <slot name="icon" />
        <slot name="title">
          <span>{{ title }}</span>
        </slot>
        <SpriteSymbol
          name="caron-down"
          class="transition-all duration-250 size-4 ml-auto shrink-0"
          :class="{
            '-scale-y-100': isOpen,
          }"
        />
      </button>
    </ContainerGrid>

    <VuepalTransitionHeight opacity>
      <div
        v-show="isOpen"
        :id="idContent"
        role="region"
        :aria-labelledby="idButton"
      >
        <div class="pb-4 lg:pb-8 md:pt-3">
          <slot />
        </div>
      </div>
    </VuepalTransitionHeight>
  </section>
</template>

<script lang="ts" setup>
import type { MediaIconFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  isGreen?: boolean
  icon?: MediaIconFragment

  /**
   * Force the accordion to be always open.
   *
   * We mostly use this in the editor, so that the contents of the accordion
   * are visible.
   */
  alwaysOpen?: boolean
}>()

const id = useId()

const idButton = computed(() => 'accordion-toggle-' + id)
const idContent = computed(() => 'accordion-content-' + id)

const isOpenByClick = ref(false)

const isOpen = computed(() => props.alwaysOpen || isOpenByClick.value)
</script>
