<template>
  <NuxtErrorBoundary>
    <NodePage v-if="node" v-bind="node" />
    <div v-else>
      <Container>
        <PageTitle title="404: Seite nicht gefunden" />
        <p class="typo-paragraph opacity-10 mb-10">
          Bitte 404 Seite mit <code>ENV.PAGE_404_NID_*</code> konfigurieren.
        </p>
        <div class="my-6 lg:my-9">
          <NuxtLink class="button" to="/">Home</NuxtLink>
        </div>
      </Container>
    </div>
    <template #error>
      <Container>
        <PageTitle title="404: Seite nicht gefunden" />
        <p class="typo-paragraph opacity-10 mb-10">
          Error beim Darstellen der 404 Seite.
        </p>
        <div class="my-6 lg:my-9">
          <NuxtLink class="button" to="/">Home</NuxtLink>
        </div>
      </Container>
    </template>
  </NuxtErrorBoundary>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()
const route = useRoute()

const { data: node } = await useAsyncGraphqlQuery('notFoundPage', null, {
  transform: (v) => v.data.get404Page,
  graphqlCaching: {
    client: true,
  },
})

const availableLanguages =
  useRuntimeConfig().public.languageNegotiation.availableLanguages

const languageLinks = computed(() => {
  const prefix = `/${language.value}/`
  const path = route.path
  // If the path does not start with the current language prefix, we can't
  // build any language links.
  if (!path.startsWith(prefix)) {
    return {}
  }

  // The language links are going to be just the invalid path in all languages,
  // e.g. /de/non-existing-page, /fr/non-existing-page, etc., *not* the alias
  // of the 404 node itself.
  return availableLanguages.reduce<Record<string, string>>((acc, langcode) => {
    acc[langcode] = path.replace(prefix, `/${langcode}/`)
    return acc
  }, {})
})

const title = computed(() => {
  const nodeTitle = node.value?.title || ''
  return `${nodeTitle} | Museum für Gestaltung Zürich`
})

useHead({
  htmlAttrs: {
    lang: language.value,
  },
  title,
})

definePageLanguageLinks(route.path, languageLinks.value)
</script>
