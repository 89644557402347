<template>
  <section v-if="events.length || isEditing" :id="anchorId" class="mt-section">
    <h2 class="h2 container section-title">
      {{ title }}
    </h2>

    <div class="border-b-1 border-stone-50">
      <div
        v-for="event in events"
        :key="event.uuid"
        class="border-t-1 border-stone-50 py-4 md:py-6 lg:py-10"
      >
        <EventTeaser class="container" v-bind="event" />
      </div>
    </div>

    <div v-if="calendarUrl" class="container mb-6 mt-8 md:mt-10">
      <NuxtLink :to="calendarUrl" class="button">
        {{ $texts('dynamic_list_event.showAllEvents', 'alle Veranstaltungen') }}
      </NuxtLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { RouteLocationRaw } from 'vue-router'
import type { ParagraphDynamicListEventFragment } from '#graphql-operations'

const props = defineProps<{
  title?: ParagraphDynamicListEventFragment['title']
  anchorTitle?: ParagraphDynamicListEventFragment['anchorTitle']
  eventsList?: ParagraphDynamicListEventFragment['eventsList']
}>()

const events = computed(() => props.eventsList?.events || [])

const language = useCurrentLanguage()

const anchorId = computed(() => slugify(props.anchorTitle || props.title))

const { isEditing } = defineBlokkli({
  bundle: 'dynamic_list_event',
  globalOptions: ['showInAnchor'],
})

const { buildQuery } = useCalendar()

const calendarUrl = computed<RouteLocationRaw | undefined>(() => {
  // This is the case if we are already displaying all events that exist.
  if (!props.eventsList?.canLoadMore) {
    return
  }

  return {
    name: 'calendar___' + language.value,
    query: buildQuery(props.eventsList.filters),
  }
})

defineOptions({
  name: 'ParagraphDynamicListEvent',
})
</script>
