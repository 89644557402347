/**
 * Put values that need to be shared between Tailwind and the runtime frontend
 * app here instead of importing it from the resolved Tailwind config, because
 * it will bundle the entire config in the client bundle.
 */
import { spacer } from './../components/Container/config'
import { cols, gap } from './../components/ContainerGrid/config'

export type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export const breakpoints: Record<Breakpoint, number> = {
  xxs: 340,
  xs: 520,
  sm: 768,
  md: 1024,
  lg: 1480,
  xl: 1920,
  xxl: 2560,
}

// the maximum width a column can have
export const maxColWidth: Omit<
  Record<Breakpoint | 'default', number>,
  'xl' | 'xxl'
> = {
  default:
    (breakpoints.xs - 2 * spacer - gap * (cols.default - 1)) / cols.default,
  xxs: (breakpoints.xxs - 2 * spacer - gap * (cols.default - 1)) / cols.default,
  xs: (breakpoints.sm - 2 * spacer - gap * (cols.default - 1)) / cols.default,
  sm: (breakpoints.md - 2 * spacer - gap * (cols.default - 1)) / cols.default,
  md: (breakpoints.lg - 2 * spacer - gap * (cols.default - 1)) / cols.default,
  lg: (breakpoints.xl - 2 * spacer - gap * (cols.lg - 1)) / cols.lg,
}

export type AspectRatioConfig = {
  number: number
  string: string
}

function aspectRatio(width: number, height: number): AspectRatioConfig {
  return {
    number: width / height,
    string: `${width}/${height}`,
  }
}

export const aspectRatios = {
  exhibition: aspectRatio(895, 1280),
  square: aspectRatio(1, 1),
} as const

export type AspectRatio = keyof typeof aspectRatios

export const rokkaMax = 2561
