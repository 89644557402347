<template>
  <footer class="bg-black text-white text-xl leading-none">
    <PageMenuLinks>
      <Container class="py-12 lg:py-19">
        <LogoPavillonHome class="mb-10 text-white hover:text-stone-700" />
        <SocialmediaMenu
          :instagram="
            $texts(
              'socialmedia.pavillon_instagram',
              'https://www.instagram.com/pavillonlecorbusier/',
            )
          "
          :facebook="
            $texts(
              'socialmedia.pavillon_facebook',
              'https://www.facebook.com/PavillonLeCorbusier/',
            )
          "
        />
      </Container>

      <PageMenuGroup :title="$texts('footer.meta', 'Entdecken & Unterstützen')">
        <FooterMenu />
      </PageMenuGroup>

      <PageMenuGroup :title="$texts('footer.mfg', 'Leitung und Kuration')">
        <div class="border-y-1 border-stone-900">
          <DrupalLink
            :to="$texts('footer.links_mfg', 'https://museum-gestaltung.ch/')"
            class="block page-menu-link"
          >
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_mfg',
                  'Museum für Gestaltung Home page.',
                )
              }}
            </span>
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_external',
                  'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
                )
              }}
            </span>

            <Container class="py-5">
              <LogoMuseum />
            </Container>
          </DrupalLink>
        </div>
      </PageMenuGroup>

      <PageMenuGroup
        :title="$texts('footer.support', 'Mit freundlicher Unterstützung')"
      >
        <div class="border-y-1 border-stone-900">
          <DrupalLink
            :to="
              $texts(
                'footer.links_zhculture',
                'https://www.stadt-zuerich.ch/kultur/de/index.html',
              )
            "
            class="block page-menu-link"
          >
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_zhculture',
                  'Stadt Zürich Kultur Seite.',
                )
              }}
            </span>
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.link_external',
                  'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
                )
              }}
            </span>
            <Container class="py-5">
              <SpriteSymbol viewBox="0 0 183 44" class="h-11" name="zh-de" />
            </Container>
          </DrupalLink>
        </div>
      </PageMenuGroup>
      <Container class="pb-12 lg:pb-19">
        <FooterMetaMenu />
      </Container>
    </PageMenuLinks>
  </footer>
</template>

<script lang="ts" setup>
const { $texts } = useNuxtApp()
</script>
