import { notNullish } from '@vueuse/core'
import type {
  GlobalConfigFragment,
  InitDataQuery,
  MenuLinkTreeElementFirstFragment,
  TermLocationTeaserFragment,
} from '#graphql-operations'

export interface InitData {
  mainMenuLinks: MenuLinkTreeElementFirstFragment[]
  footerMenuLinks: MenuLinkTreeElementFirstFragment[]
  footerMetaMenuLinks: MenuLinkTreeElementFirstFragment[]
  targetGroupMenuLinks: MenuLinkTreeElementFirstFragment[]
  locationItems: TermLocationTeaserFragment[]
  translations: Record<string, string | [string, string]>
  globalConfig: {
    address?: GlobalConfigFragment['address']
    termsOfService?: string
    conditions?: string
  }
}

function getTranslations(
  v?: InitDataQuery,
): Record<string, string | [string, string]> {
  if (!v) {
    return {}
  }
  return Object.entries(
    (v.translations || {}) as Record<
      string,
      string | { singular?: string; plural?: string }
    >,
  ).reduce<Record<string, string | [string, string]>>(
    (acc, [fullKey, value]) => {
      const keyWithDots = fullKey.replace('__', '.')
      if (typeof value === 'string') {
        acc[keyWithDots] = value
      } else if (typeof value === 'object' && value.plural && value.singular) {
        acc[keyWithDots] = [value.singular, value.plural]
      }
      return acc
    },
    {},
  )
}

export default async function (): Promise<Ref<InitData>> {
  const currentLanguage = useCurrentLanguage()
  const data = useState<InitData>('initData')

  const host = useHostName()

  // Let's try to fetch the data from the cache first.
  const event = useRequestEvent()
  const { value, addToCache } = await useDataCache<InitData>(
    'initData_' + currentLanguage.value + '_' + host,
    event,
  )
  if (value) {
    data.value = value
    return data
  }

  // If the data is not in the cache, we fetch it from the payload / useState().
  if (data.value) {
    return data
  }

  // Fetch the data from the server.
  data.value = await useGraphqlQuery({
    name: 'initData',
    fetchOptions: {
      query: {
        __l: currentLanguage.value,
      },
      headers: {
        host,
        'x-forwarded-host': host,
      },
    },
  }).then((v) => {
    const initData = {
      mainMenuLinks: v.data?.mainMenu?.links || [],
      footerMenuLinks: v.data?.footerMenu?.links || [],
      footerMetaMenuLinks: v.data?.footerMetaMenu?.links || [],
      targetGroupMenuLinks: v.data?.targetGroupMenu?.links || [],
      locationItems: (v.data?.locationsQuery?.items || [])
        .map((v) => {
          if ('image' in v) {
            return v
          }

          return null
        })
        .filter(notNullish),
      translations: getTranslations(v.data),
      globalConfig: {
        address: v.data.globalConfig?.address,
        termsOfService: v.data.globalConfig?.termsOfService?.uri?.path,
        conditions: v.data.globalConfig?.conditions?.uri?.path,
      },
    }

    // The cache tags are coming from the onServerResponse() function in the graphqlMiddleware.
    if (
      import.meta.server &&
      v.__cacheability?.isCacheable &&
      v.__cacheability.tagsNuxt
    ) {
      addToCache(initData, v.__cacheability.tagsNuxt)
    }
    return initData
  })

  return data
}
