<template>
  <div class="button">
    <slot name="prepend" />
    <slot />
    <slot name="extra" />
  </div>
</template>

<style lang="postcss">
.bk-edit-indicator {
  @apply !top-32;
}
</style>
