import { defineFormKitConfig } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import type {
  FormKitExtendableSchemaRoot,
  FormKitNode,
  FormKitSchemaCondition,
  FormKitSchemaNode,
} from '@formkit/core'
import { clone } from '@formkit/utils'
import { de, fr, it } from '@formkit/i18n'
import {
  arrowDown,
  arrowUp,
  check,
  circle,
  close,
  down,
  fileDoc,
  spinner,
  star,
  trash,
} from '@formkit/icons'
import { rootClasses } from './formkit.theme'

const proPlugin = createProPlugin('fk-6ce89938df1', inputs)

function isSchemaFunction(
  schema:
    | FormKitExtendableSchemaRoot
    | FormKitSchemaNode[]
    | FormKitSchemaCondition
    | undefined,
): schema is FormKitExtendableSchemaRoot {
  return schema !== undefined && typeof schema === 'function'
}

/**
 * Adds an asterisk to required input labels.
 */
function addAsteriskPlugin(node: FormKitNode) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  ) {
    return
  }
  const legendOrLabel = ['checkbox', 'radio'].includes(node.props.type)
    ? 'legend'
    : 'label'

  if (!node.props.definition) {
    return
  }

  const inputDefinition = clone(node.props.definition)
  const originalSchema = inputDefinition.schema

  if (!isSchemaFunction(originalSchema)) {
    return
  }

  if (node.props.definition.schemaMemoKey) {
    node.props.definition!.schemaMemoKey += 'add_asterisks'
  }

  node.props.definition!.schema = (sectionsSchema = {}) => {
    sectionsSchema[legendOrLabel] = {
      children: [
        '$label',
        {
          $el: 'span',
          if: '$state.required',
          attrs: {
            class: '$classes.asterisk',
          },
          children: ['*'],
        },
      ],
    }

    return originalSchema(sectionsSchema)
  }
}

/**
 * Aria plugin.
 */
function ariaPlugin(node: FormKitNode) {
  node.on('created', () => {
    if (!node.props.definition) {
      return
    }

    node.props.definition.schemaMemoKey = `aria-invalid_${node.props.definition.schemaMemoKey}`

    const schemaFn = node.props.definition.schema
    if (!schemaFn) {
      return
    }
    node.props.definition.schema = (sectionsSchema = {}) => {
      // Set the aria-invalid attribute.
      sectionsSchema.input = {
        attrs: {
          'aria-invalid': '$state.invalid',
        },
      }

      // @ts-ignore
      return schemaFn(sectionsSchema)
    }
  })
}

export default defineFormKitConfig(() => {
  const language = useCurrentLanguage()
  return {
    plugins: [proPlugin, addAsteriskPlugin, ariaPlugin],
    config: {
      rootClasses,
    },
    locales: { de, fr, it },
    locale: language.value,
    icons: {
      arrowDown,
      arrowUp,
      close,
      checkboxDecorator: check,
      fileItem: fileDoc,
      fileRemove: close,
      noFiles: fileDoc,
      radioDecorator: circle,
      // select: down,
      spinner,
      star,
      trash,
      select: down,
    },
  }
})
