import { useWindowSize } from '@vueuse/core'

type UseViewport = {
  isMobile: ComputedRef<boolean>
  isTouch: ComputedRef<boolean>
}

export default function (): UseViewport {
  const { breakpoints } = useTailwind()
  const { width } = useWindowSize()

  const isMobile = computed(
    () => import.meta.server || width.value < breakpoints.xs,
  )

  const isTouch = computed(
    () => import.meta.server || width.value < breakpoints.md,
  )

  return { isMobile, isTouch }
}
