<template>
  <div class="my-20">
    <ContainerGrid class="container">
      <div class="basis-full sm:basis-1/4">
        <div
          v-if="image"
          class="size-[207px] overflow-hidden mx-auto sm:me-0"
          :class="{
            'sm:me-0': options.alignment === 'left',
            'sm:ms-0': options.alignment === 'right',
          }"
        >
          <RokkaImage
            :config="imageStyle"
            v-bind="image.image"
            :hash="image?.image?.file?.rokka?.hash || ''"
          />
        </div>
      </div>
      <div class="col-span-4 sm:col-start-3 sm:col-span-8">
        <figure
          class="flex flex-col text-left"
          :class="`sm:text-${options.alignment}`"
        >
          <blockquote>
            <span
              v-blokkli-editable:field_quote
              class="text-3xl before:text-3xl md:mb-0 mb-20 before:content-['«'] after:content-['»']"
              >{{ quote }}</span
            >
          </blockquote>
          <figcaption class="mt-8">
            <p v-blokkli-editable:field_name>
              {{ name }}
            </p>
            <p v-blokkli-editable:field_company>
              {{ company }}
            </p>
          </figcaption>
        </figure>
      </div>
    </ContainerGrid>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphQuoteFragment } from '#graphql-operations'

defineProps<{
  quote?: ParagraphQuoteFragment['quote']
  name?: ParagraphQuoteFragment['name']
  company?: ParagraphQuoteFragment['company']
  image?: ParagraphQuoteFragment['image']
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 207,
  },
})

const { options } = defineBlokkli({
  bundle: 'quote',
  editor: {
    previewWidth: 600,
    editTitle: (el) => el.querySelector('figcaption')?.textContent,
    addBehaviour: 'no-form',
  },
  options: {
    alignment: {
      type: 'radios',
      label: 'Alignment',
      default: 'left',
      displayAs: 'icons',
      options: {
        left: {
          label: 'Bild links',
          icon: 'icon-blokkli-option-image-left',
        },
        right: {
          label: 'Bild rechts',
          icon: 'icon-blokkli-option-image-right',
        },
      },
    },
  },
})
</script>
