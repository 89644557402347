<template>
  <div
    :id="id"
    class="container paragraph-text peer/text grid gap-medium"
    :class="{
      'mt-section': !parentType,
      '!mt-0 not-last:mb-7': parentType,
    }"
  >
    <component
      :is="headerSize"
      v-if="title"
      class="max-w-screen-md text-balance"
      :class="headerSize"
    >
      {{ title }}
    </component>

    <div v-if="image" v-blokkli-droppable:field_image>
      <MediaImage v-bind="image" :image-style="defaultImageStyle" />
    </div>

    <div v-if="text">
      <ContainerGrid>
        <div class="col-span-full lg:col-span-10 xl:col-span-8">
          <div
            v-blokkli-editable:field_text
            class="ck-content hyphens-auto lg:hyphens-none"
            v-html="text"
          />
        </div>
      </ContainerGrid>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphTextFragment } from '#graphql-operations'

const { maxWidthForCols } = useTailwind()

const props = defineProps<{
  title?: string
  anchorTitle?: string
  text?: string
  image?: ParagraphTextFragment['image']
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'text',
  globalOptions: ['showInAnchor'],
  options: {
    headerSize: {
      type: 'radios',
      label: 'Titelgrösse',
      default: 'h2',
      displayAs: 'icons',
      options: {
        h2: { label: 'H2', icon: 'icon-blokkli-option-h2' },
        h3: { label: 'H3', icon: 'icon-blokkli-option-h3' },
      },
    },
  },
})

const id = computed(() => slugify(props.anchorTitle || props.title))

const headerSize = computed(() => {
  if (options.value.headerSize === 'h2') {
    return 'h2'
  }
  return 'h3'
})

const defaultImageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    default: maxWidthForCols('default', 6),
    xs: maxWidthForCols('xs', 6),
    sm: maxWidthForCols('sm', 6),
    md: maxWidthForCols('md', 6),
    lg: maxWidthForCols('lg', 12),
  },
})
</script>
