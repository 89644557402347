<template>
  <nav
    v-if="tabs.length"
    ref="anchorNav"
    class="pointer-events-none"
    :aria-label="$texts('accessibility.in_page_navigation', 'Seitennavigation')"
  >
    <div class="container">
      <div ref="elTabs">
        <ul ref="elTabsList" class="-ml-[3px] grid gap-2">
          <li v-for="item in tabs" :key="item.id" ref="tabItems">
            <a
              :href="'#' + item.id"
              class="button is-anchor pointer-events-auto"
            >
              <SpriteSymbol name="arrow-down" />
              {{ item.label }}
              <span class="sr-only">
                {{
                  $texts(
                    'accessibility.link_internal',
                    'Direkt zum Abschnitt springen.',
                  )
                }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type { FieldListItem } from '#blokkli/types'

interface AnchorItem {
  label: string
  id: string
}

const props = withDefaults(
  defineProps<{
    anchors?: AnchorItem[]
    paragraphs?: FieldListItem[]
    overlay?: boolean
  }>(),
  {
    anchors: () => [],
    paragraphs: () => [],
    overlay: false,
  },
)

const paragraphAnchors = defineAnchorLinks((v) =>
  v.addParagraphs(props.paragraphs),
)

const tabs = computed(() => [...props.anchors, ...paragraphAnchors.value])

defineOptions({
  name: 'InPageNavigation',
})
</script>
