<template>
  <RokkaImage
    :hash="videoMobile?.file?.rokka?.hash || ''"
    :config="imageStyle"
    preload
    loading="eager"
    class="absolute top-0 left-0 size-full xs:hidden"
    :source-width="videoMobile?.file?.rokka?.sourceWidth"
    :source-height="videoMobile?.file?.rokka?.sourceHeight"
    :alt="$texts('accessibility.header_picture', 'Header Bild von Video')"
    aria-hidden="true"
  />
  <ClientOnly>
    <LazyMediaVideoPlayer
      v-if="source"
      :key="source.src"
      ref="mediaVideoPlayer"
      :poster="source.poster"
      :sources="[
        {
          src: source.src,
          type: 'application/x-mpegURL',
        },
      ]"
      :autoplay="autoplay"
      :loop="loop"
      class="absolute top-0 left-0 size-full"
      :controls="controls"
      :muted="muted"
      :video-aria-hidden="videoAriaHidden"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { RokkaImage } from '#components'
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaVideoPlayer } from '#build/components'
import type { MediaVideoFragment, VideoItemFragment } from '#graphql-operations'
import { getRokkaUrl } from '~/composables/getRokkaUrl'

type MediaVideoProps = MediaVideoFragment & {
  autoplay?: boolean
  loop?: boolean
  controls?: boolean
  poster?: string
  muted?: boolean
  videoAriaHidden?: boolean
  imageStyle: DefineImageStyleConfig | string
}

const { isMobile } = useViewport()

const props = defineProps<MediaVideoProps>()

type Source = {
  poster?: string
  src: string
}

function buildSource(video?: VideoItemFragment): Source | undefined {
  if (!video) {
    return
  }
  const hash = video.file?.rokka?.hash

  if (!hash) {
    return
  }

  const poster = props.poster ?? getRokkaUrl(hash)
  const src = getRokkaUrl(hash, 'm3u')

  if (!src) {
    return
  }

  return {
    poster,
    src,
  }
}

const sourceDesktop = computed<Source | undefined>(() =>
  buildSource(props.video),
)
const sourceMobile = computed<Source | undefined>(() =>
  buildSource(props.videoMobile),
)

const source = computed(() => {
  if (isMobile.value && sourceMobile.value) {
    return sourceMobile.value
  }

  return sourceDesktop.value
})

const mediaVideoPlayer = ref<typeof MediaVideoPlayer>()

const play = () => {
  if (mediaVideoPlayer.value) {
    mediaVideoPlayer.value.play()
  }
}
defineExpose({
  play,
})

defineOptions({
  name: 'MediaVideo',
})
</script>
