<template>
  <div v-if="shouldRender" :id="id" class="mt-section">
    <Container>
      <h2 class="h2 section-title">{{ title }}</h2>
      <div v-if="image" v-blokkli-droppable:field_image class="my-6">
        <MediaImage v-bind="image" :image-style="defaultImageStyle" />
      </div>
      <ContainerGrid v-if="text" class="my-5 md:my-8">
        <div
          v-blokkli-editable:field_text
          class="ck-content hyphens-auto lg:hyphens-none col-span-full lg:col-span-8"
          v-html="text"
        />
      </ContainerGrid>
    </Container>

    <BlokkliField
      v-slot="{ items }"
      name="field_paragraphs_teaser"
      :list="paragraphs"
      edit-only
      class="absolute flex bottom-0 left-0 w-full bg-black justify-center py-5 gap-5 mt-5"
    >
      <TeaserSlider
        v-if="items.length"
        :id="uuid"
        v-slot="{ item }"
        :slides="getFilteredSlides(items)"
        :type="getSliderType(items)"
        class="mt-medium"
      >
        <BlokkliItem v-bind="item" />
      </TeaserSlider>
    </BlokkliField>

    <Container v-if="link" class="mb-6 mt-9 md:mt-12">
      <DrupalLink v-slot="{ isExternal }" :to="link?.uri?.path" class="button">
        <span v-if="isExternal" class="sr-only">
          {{
            $texts(
              'accessibility.link_external',
              'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
            )
          }}
        </span>
        {{ link?.title }}
      </DrupalLink>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { FieldListItem } from '#blokkli/types'
import type {
  ParagraphTeaserFragment,
  ParagraphTeaserListFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: string
  anchorTitle?: string
  text?: string
  image?: ParagraphTeaserListFragment['image']
  link?: ParagraphTeaserListFragment['link']
  paragraphs?: ParagraphTeaserListFragment['paragraphs']
}>()

const { isEditing, uuid } = defineBlokkli({
  bundle: 'teaser_list',
  globalOptions: ['showInAnchor'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
    addBehaviour: 'no-form',
  },
})

function getFilteredSlides(items: FieldListItem[]): FieldListItem[] {
  if (isEditing) {
    return items
  }

  return items.filter((v) => {
    if (v.bundle === 'teaser') {
      const teaserProps = v.props as ParagraphTeaserFragment

      return teaserProps.link?.uri?.path || teaserProps.node?.__typename
    }

    return true
  })
}

const id = computed(() => slugify(props.anchorTitle || props.title))

const shouldRender = computed<boolean>(
  () =>
    isEditing ||
    !!props.paragraphs?.find(
      (v) =>
        v &&
        v.props &&
        'title' in v.props &&
        (v.props.link?.uri?.path || v.props.node),
    ),
)

function getSliderType(items: FieldListItem[]): 'exhibition' | 'square' {
  // We only care about the first teaser.
  const first = items[0]
  if (first?.bundle === 'teaser') {
    const teaserProps: ParagraphTeaserFragment | undefined = first.props
    if (teaserProps && teaserProps.node?.__typename === 'NodeExhibition') {
      return 'exhibition'
    }
  }

  return 'square'
}

const defaultImageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 770,
    sm: 984,
    md: 1380,
    lg: 1380,
  },
})
</script>
