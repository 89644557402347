<template>
  <NuxtLink :to="url" class="group/link container-grid sm:grid-cols-4 group">
    <div
      class="aspect-square col-span-4 sm:col-span-4 lg:col-span-3 overflow-hidden transition md:group-hover:opacity-90"
      :class="inverted ? 'bg-stone-900' : 'bg-stone-50'"
    >
      <MediaImage
        v-if="image"
        hide-caption
        :image-style="imageStyle"
        v-bind="image"
        :class="{ grayscale: inverted }"
        img-class="md:group-hover:scale-105 transition ease-swing"
      />
    </div>

    <div class="col-start-5 col-end-[-1] sm:col-start-1 sm:mt-4">
      <component
        :is="labelTag || 'h3'"
        class="font-bold typo-teaser-title mb-teaser"
        :class="{
          'text-white': inverted,
        }"
      >
        {{ label }}
      </component>

      <ClientOnly>
        <LocationOpenStatus
          :id="id"
          class="typo-teaser-text leading-none"
          :class="{
            'text-white': inverted,
          }"
        />
      </ClientOnly>

      <div
        class="sr-only"
        :class="{
          'is-inverted': inverted,
        }"
      >
        {{ $texts('location.go_to_link', 'Zum Standort') }}
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  TermLocationTeaserFragment,
} from '#graphql-operations'

const props = defineProps<{
  label?: string
  id?: string
  image?: MediaImageFragment
  page?: TermLocationTeaserFragment['page']

  inverted?: boolean
  labelTag?: keyof HTMLElementTagNameMap
}>()

const url = computed(() => props.page?.url?.path)

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 200,
    md: 308,
  },
})
</script>
