<template>
  <button
    aria-controls="main-menu-panel"
    :aria-expanded="isOpen"
    class="button transition-all ease-in-out duration-250 justify-center"
    :class="{
      'is-high-contrast max-lg:!px-0 w-8 xxs:w-[42px] md:w-12 lg:w-auto !py-0':
        isOpen,
    }"
    @click="isOpen = !isOpen"
  >
    <div v-if="isOpen" class="flex items-center gap-2">
      <span aria-hidden="true" class="hidden lg:block">
        {{ $texts('close', 'Schliessen') }}
      </span>
      <SpriteSymbol name="close" />
      <span class="sr-only">
        {{
          $texts('accessibility.main_menu_close', 'Das Hauptmenü schliessen')
        }}
      </span>
    </div>

    <div v-else>
      <span aria-hidden="true">
        {{ $texts('header.menu', 'Menü') }}
      </span>
      <span class="sr-only">
        {{ $texts('accessibility.main_menu_open', 'Das Hauptmenü öffnen') }}
      </span>
    </div>
  </button>
</template>

<script lang="ts" setup>
const isOpen = defineModel<boolean>({
  default: false,
})
</script>
