<template>
  <nav :aria-label="$texts('accessibility.main_navigation', 'Hauptnavigation')">
    <ul>
      <li
        v-for="(link, index) in links"
        :key="`global_${index}`"
        class="border-b-1 first:border-t-1 border-stone-900"
      >
        <DrupalLink
          v-slot="{ isExternal }"
          :to="link.link?.url?.path"
          class="text-2xl xxs:text-3xl md:text-4xl lg:text-6xl font-bold py-5 xxs:py-8 sm:py-10 lg:py-12 block page-menu-link"
          active-class="active"
        >
          <div class="container flex">
            <span v-if="isExternal" class="sr-only">
              {{
                $texts(
                  'accessibility.link_external',
                  'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
                )
              }}
            </span>
            {{ link.link.label }}
            <SpriteSymbol
              v-if="isExternal"
              name="external-link"
              class="h-[1em] inline-block aspect-[80/96] ml-[0.5em]"
            />
          </div>
        </DrupalLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const initData: Ref<InitData> = await useInitData()

const links = computed(() => {
  return initData.value.mainMenuLinks
})
</script>
