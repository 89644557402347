import { notNullish } from '@vueuse/core'
import type {
  VisitorTableTitle,
  VisitorTableValue,
} from '~/components/VisitorTableRow/types'
const INJECT_KEY = Symbol('visitor_table_data')

type VisitorTableData = {
  title: VisitorTableTitle
  value: VisitorTableValue[]
}

type VisitorTableDataDefiner = {
  add: (
    title: VisitorTableTitle,
    value: VisitorTableValue | VisitorTableValue[],
    condition?: ComputedRef<boolean | string>,
  ) => VisitorTableDataDefiner
}

/**
 * Composable to define and provide data for the "Visitor Table" paragraph.
 */
export function defineVisitorTableData(
  cb: (definer: VisitorTableDataDefiner) => void,
) {
  // Computed property so that the data is actually reactive.
  // Vue will call the provided callback when any of its dependencies
  // updates.
  const infoData = computed(function () {
    // Collect the defined data.
    const data: VisitorTableData[] = []

    const definer: VisitorTableDataDefiner = {
      add: function (
        title: VisitorTableTitle,
        value: VisitorTableValue | VisitorTableValue[],
        condition?: ComputedRef<boolean | string>,
      ) {
        if (condition && !condition.value) {
          return this
        }
        // Build an array and filter nullish values.
        const valueArray = (Array.isArray(value) ? value : [value]).filter(
          notNullish,
        )

        if (valueArray.length) {
          data.push({ title, value: valueArray })
        }
        return this
      },
    }

    cb(definer)

    return data
  })

  provide(INJECT_KEY, infoData)
}

export function useVisitorTableData(): ComputedRef<VisitorTableData[]> {
  const injected = inject<ComputedRef<VisitorTableData[]> | null>(
    INJECT_KEY,
    null,
  )

  if (injected) {
    return injected
  }

  return computed(() => [])
}
