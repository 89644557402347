
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'calendar'
  | 'caron-down'
  | 'caron-left'
  | 'caron-right'
  | 'caron-up'
  | 'check'
  | 'close'
  | 'external-link'
  | 'filter'
  | 'info'
  | 'bak-de'
  | 'bak-en'
  | 'bak-fr'
  | 'zh-de'
  | 'zhdk'
  | 'menu'
  | 'play'
  | 'search'
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'mastodon'
  | 'threads'
  | 'tiktok'
  | 'youtube'
  | 'warning'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.QvmQhCH3qV.svg"}

export type RuntimeOptions = {
  ariaHidden: boolean
}

export const runtimeOptions: RuntimeOptions = {"ariaHidden":true}
