<template>
  <nav
    :aria-label="
      $texts('accessibility.locations_navigation', 'Standorte Navigation')
    "
  >
    <Container>
      <OrderedList
        v-slot="{ item }"
        :list="locationItems"
        class="grid grid-cols-1 sm:grid-cols-3 gap-gap break-words"
      >
        <TermLocationTeaser v-bind="item" inverted label-tag="h2" />
      </OrderedList>
    </Container>
  </nav>
</template>

<script lang="ts" setup>
const initData: Ref<InitData> = await useInitData()
const { $texts } = useNuxtApp()

const locationItems = computed(() => initData.value.locationItems)
</script>
