<template>
  <div :id="anchorId" class="my-section">
    <h2 class="h2 container section-title">
      {{ title }}
    </h2>

    <div v-if="timelineOptions.length > 1" class="container mb-4 md:mb-5">
      <div class="flex flex-wrap gap-2">
        <button
          v-for="option in timelineOptions"
          :key="option.timeline"
          class="button"
          :class="{
            'is-high-contrast': option.active,
          }"
          @click="selectedTimeline = option.timeline"
        >
          {{ option.label }}
        </button>
      </div>
    </div>

    <TeaserSlider
      :id="uuid + selectedTimeline"
      :key="data?.key"
      :slides="exhibitions"
      aspect-ratio="exhibition"
    >
      <template #default="{ item }">
        <TeaserExhibition v-bind="item" />
      </template>
    </TeaserSlider>

    <div v-if="!exhibitions?.length" class="container">
      <p>
        {{
          $texts(
            'no_exhibitions',
            'Keine Ausstellungen für den gewählten Zeitraum.',
          )
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import { notNullish } from '@vueuse/core'
import {
  ExhibitionTimelineStatus,
  type GetExhibitionsQueryVariables,
  type ParagraphDynamicListExhibitionFragment,
} from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    title?: ParagraphDynamicListExhibitionFragment['title']
    anchorTitle?: ParagraphDynamicListExhibitionFragment['anchorTitle']
    availableTimelines?: ParagraphDynamicListExhibitionFragment['availableTimelines']
    types?: ParagraphDynamicListExhibitionFragment['types']
    location?: ParagraphDynamicListExhibitionFragment['location']
  }>(),
  {
    title: '',
    anchorTitle: '',
    availableTimelines: function () {
      return [ExhibitionTimelineStatus.CURRENT]
    },
    types: undefined,
    location: undefined,
  },
)

const { uuid } = defineBlokkli({
  bundle: 'dynamic_list_exhibition',
  globalOptions: ['showInAnchor'],
})

const { $texts } = useEasyTexts()

const TIMELINE_ORDER = [
  ExhibitionTimelineStatus.CURRENT,
  ExhibitionTimelineStatus.FUTURE,
  ExhibitionTimelineStatus.PAST,
]

const selectableTimelines = computed<ExhibitionTimelineStatus[]>(() =>
  [...(props.availableTimelines || [])].sort((a, b) => {
    const indexA = TIMELINE_ORDER.indexOf(a)
    const indexB = TIMELINE_ORDER.indexOf(b)
    return indexA - indexB
  }),
)

function getTimelineLabel(v: ExhibitionTimelineStatus): string {
  switch (v) {
    case ExhibitionTimelineStatus.CURRENT:
      return $texts('exhibitionCurrent', 'Aktuell')
    case ExhibitionTimelineStatus.PAST:
      return $texts('exhibitionPast', 'Archiv')
    case ExhibitionTimelineStatus.FUTURE:
      return $texts('exhibitionFuture', 'Kommende')
  }
}

type TimelineOption = {
  timeline: ExhibitionTimelineStatus
  label: string
  active: boolean
}

const timelineOptions = computed<TimelineOption[]>(() =>
  selectableTimelines.value.map((timeline) => {
    return {
      timeline,
      label: getTimelineLabel(timeline),
      active: selectedTimeline.value === timeline,
    }
  }),
)

const anchorId = computed(() => slugify(props.anchorTitle || props.title))

const defaultTimeline = computed<ExhibitionTimelineStatus>(
  () => selectableTimelines.value[0],
)

const route = useRoute()
const router = useRouter()

const selectedTimeline = computed({
  get() {
    const query = route.query[anchorId.value]
    if (typeof query === 'string') {
      const match = selectableTimelines.value.find((v) => v === query)
      if (match) {
        return match
      }
    }
    return defaultTimeline.value
  },
  set(timeline: ExhibitionTimelineStatus) {
    router.replace({
      hash: '#' + anchorId.value,
      query: {
        ...route.query,
        [anchorId.value]:
          timeline === defaultTimeline.value ? undefined : timeline,
      },
    })
  },
})

const query = computed<GetExhibitionsQueryVariables | undefined>(() => {
  const location = props.location?.first?.id
    ? String(props.location.first.id)
    : undefined
  const types = props.types?.list?.map((v) => String(v.id)).filter(notNullish)

  return {
    location,
    types,
    limit: 12,
    timeline: selectedTimeline.value,
  }
})

const { data } = await useAsyncData(
  uuid,
  () => {
    if (!query.value) {
      return Promise.resolve({
        exhibitions: [],
        key: 'none',
      })
    }

    return useGraphqlQuery('getExhibitions', query.value, {
      graphqlCaching: {
        client: true,
      },
    }).then((v) => {
      const exhibitions = v.data.exhibitions || []
      return {
        exhibitions,
        key: Date.now().toString(),
      }
    })
  },
  {
    watch: [query],
  },
)

const exhibitions = computed(() => data.value?.exhibitions || [])
</script>
